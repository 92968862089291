import { Command } from '@lookiero/messaging.js';

class PrintRouteDocuments extends Command {
  readonly routeId: string;
  readonly line: string;
  readonly documents?: string[];

  constructor(routeId: string, line: string, documents?: string[]) {
    super();
    this.routeId = routeId;
    this.line = line;
    this.documents = documents;
  }

  /* istanbul ignore next */
  protected messageName(): string {
    return 'PrintRouteDocuments';
  }
}

export default PrintRouteDocuments;
