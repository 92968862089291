import NotificationRepository from 'core/domain/model/notification/NotificationRepository';
import Notification from 'core/domain/model/notification/Notification';
import { CommandHandler, EventBus } from '@lookiero/messaging.js';

import CreateNotification from './CreateNotification';

class CreateNotificationHandler implements CommandHandler<CreateNotification> {
  private readonly repository: NotificationRepository;
  eventBus!: EventBus;

  public constructor(repository: NotificationRepository) {
    this.repository = repository;
  }

  public execute({ notificationId, level, content, contentHeader }: CreateNotification): Promise<void> {
    const notification = Notification.create(notificationId, level, content, contentHeader);

    return this.repository.save(notification).then(() => this.eventBus.publish([...notification.recordedEvents()]));
  }
}

export default CreateNotificationHandler;
