import { DomainEvent } from '@lookiero/messaging.js';

class SendPalletsFailed extends DomainEvent {
  public constructor() {
    super();
  }

  /* istanbul ignore next */
  public messageName(): string {
    return 'SendPalletsFailed';
  }
}

export default SendPalletsFailed;
