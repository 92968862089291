import { CommandHandler, EventBus } from '@lookiero/messaging.js';
import PalletLabelRepository from 'core/domain/model/pallet/write/PalletWriter';
import PrintLabel from 'core/application/pallet/printPallet/PrintLabel';
import Pallet from 'core/domain/model/pallet/write/Pallet';
import PrintPalletLabelsFailed from 'core/domain/model/pallet/write/event/printPalletLabelsFailed/PrintPalletLabelsFailed';

class PrintLabelHandler implements CommandHandler<PrintLabel> {
  private readonly palletLabelRepository: PalletLabelRepository;
  eventBus!: EventBus;

  public constructor(palletLabelRepository: PalletLabelRepository) {
    this.palletLabelRepository = palletLabelRepository;
  }

  public async execute({ palletCount, shippingTypes }: PrintLabel): Promise<void> {
    try {
      const pallet = Pallet.printPalletLabels(palletCount, shippingTypes);
      await this.palletLabelRepository.printLabels(pallet);

      this.eventBus.publish([...pallet.recordedEvents()]);
    } catch (error) {
      this.eventBus.publish([new PrintPalletLabelsFailed()]);
    }
  }
}

export default PrintLabelHandler;
