import { DomainEvent } from '@lookiero/messaging.js';

class RouteAssignedToTrolley extends DomainEvent {
  public constructor() {
    super();
  }

  /* istanbul ignore next */
  public messageName(): string {
    return 'RouteAssignedToTrolley';
  }
}

export default RouteAssignedToTrolley;
