import { Command } from '@lookiero/messaging.js';

class SendPallets extends Command {
  public palletIds: string[];

  public constructor(palletIds: string[]) {
    super();
    this.palletIds = palletIds;
  }

  /* istanbul ignore next */
  protected messageName(): string {
    return 'SendPallet';
  }
}

export default SendPallets;
