import { Query } from '@lookiero/messaging.js';
import { RouteStatusFilter } from 'core/domain/model/route/read/RouteStatusFilter';
import { RouteType } from 'core/domain/model/route/read/RouteType';

type FindRouteParameter = {
  readonly pickerId: string;
  readonly routeTypes: RouteType[];
  readonly statusFilter: RouteStatusFilter;
};
class FindRoutes extends Query {
  readonly pickerId: string;
  readonly routeTypes: RouteType[];
  readonly statusFilter: RouteStatusFilter;

  constructor({ pickerId, routeTypes, statusFilter }: FindRouteParameter) {
    super();
    this.pickerId = pickerId;
    this.routeTypes = routeTypes;
    this.statusFilter = statusFilter;
  }

  /* istanbul ignore next */
  protected messageName(): string {
    return 'FindRoutes';
  }
}

export default FindRoutes;
