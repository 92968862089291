import DomainError from 'shared/domain/error/DomainError';
import ErrorCode from 'shared/domain/error/ErrorCode';
import { I18N_MESSAGES } from 'shared/i18n/inventory';

class InventoryReferenceInvalidError extends DomainError {
  public constructor() {
    super(
      ErrorCode.INVENTORY_REFERENCE_INVALID,
      I18N_MESSAGES.INVENTORY_ACTIONS_TEMPLATE_REFERENCE_ERROR,
      InventoryReferenceInvalidError.name,
    );
  }
}

export default InventoryReferenceInvalidError;
