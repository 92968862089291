import { AxiosError, AxiosInstance } from 'axios';
import LogoutEmployee from 'core/application/employee/logout/LogoutEmployee';
import { CommandBus } from '@lookiero/messaging.js';
import { HttpErrorCodes } from 'shared/domain/delivery/HttpClient';
import EmployeeReader from 'core/domain/model/employee/read/EmployeeReader';

class AxiosHttpClientInterceptor {
  employeeReader!: EmployeeReader;
  commandBus!: CommandBus;

  public config(axiosInstance: AxiosInstance): void {
    this.configRequest(axiosInstance);
    this.configResponse(axiosInstance);
  }

  private configRequest(axiosInstance: AxiosInstance): void {
    axiosInstance.interceptors.request.use(async (request) => {
      if (this.employeeReader) {
        const employee = this.employeeReader.getCurrentEmployeeView();
        request.headers['Authorization'] = `Bearer ${employee ? employee.authToken : null}`;
      }
      return request;
    });
  }

  private configResponse(axiosInstance: AxiosInstance): void {
    axiosInstance.interceptors.response.use(undefined, (error: AxiosError) => {
      if (this.commandBus && this.isBearerNotValid(error)) {
        this.commandBus.dispatch(new LogoutEmployee());
      }
      return Promise.reject(error);
    });
  }

  private isBearerNotValid(error: AxiosError): boolean {
    const bearerInvalid = [HttpErrorCodes.UNAUTHORIZED, HttpErrorCodes.FORBIDDEN];
    return error.response !== undefined && bearerInvalid.includes(error.response.status);
  }
}

export default AxiosHttpClientInterceptor;
