import StoragePrinterReader from 'core/infrastructure/persistence/printer/read/StoragePrinterReader';
import HttpPrinterReader from 'core/infrastructure/persistence/printer/read/HttpPrinterReader';
import PrinterReader from 'core/domain/model/printer/read/PrinterReader';
import { PrinterView } from 'core/domain/model/printer/read/PrinterView';

class PrinterReaderAdapter implements PrinterReader {
  private readonly httpPrinterReader: HttpPrinterReader;
  private readonly storagePrinterReader: StoragePrinterReader;

  constructor(httpPrinterReader: HttpPrinterReader, storagePrinterReader: StoragePrinterReader) {
    this.httpPrinterReader = httpPrinterReader;
    this.storagePrinterReader = storagePrinterReader;
  }

  public async findPrinters(): Promise<PrinterView[]> {
    const preparerPrinter = this.storagePrinterReader.findPreparerPrinter();
    const returnsPrinter = this.storagePrinterReader.findReturnsPrinter();

    return this.httpPrinterReader
      .findPrinters()
      .then((lines: string[]) => lines.map((line) => this.toDomain(line, preparerPrinter, returnsPrinter)))
      .then((printerViews: PrinterView[]) => this.sortByLineAsc(printerViews));
  }

  private toDomain(
    line: string,
    selectedPreparerPrinter: string | null,
    selectedReturnsPrinter: string | null,
  ): PrinterView {
    const retrievedLineNumber = Number(line.match(/\d+/g));
    return {
      line: retrievedLineNumber,
      isPreparerPrinter: selectedPreparerPrinter === retrievedLineNumber.toString(),
      isReturnsPrinter: selectedReturnsPrinter === retrievedLineNumber.toString(),
    };
  }

  private sortByLineAsc(printerViews: PrinterView[]): PrinterView[] {
    return printerViews.sort((first, second) => first.line - second.line);
  }
}

export default PrinterReaderAdapter;
