import { Command } from '@lookiero/messaging.js';

class AssignRouteToTrolley extends Command {
  readonly routeId: string;
  readonly trolleyId: string;

  constructor(routeId: string, trolleyId: string) {
    super();
    this.routeId = routeId;
    this.trolleyId = trolleyId;
  }

  /* istanbul ignore next */
  protected messageName(): string {
    return 'AssignRouteToTrolley';
  }
}

export default AssignRouteToTrolley;
