import { Command } from '@lookiero/messaging.js';

class ThrowError extends Command {
  public readonly error: Error;

  public constructor(error: Error) {
    super();
    this.error = error;
  }

  public messageName(): string {
    return 'ThrowError';
  }
}

export default ThrowError;
