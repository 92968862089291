const PREFIX = 'root.';

const I18N_MESSAGES = {
  SIDE_MENU_ROUTES: 'side_menu.routes',
  SIDE_MENU_PICKING: 'side_menu.picking',
  SIDE_MENU_SHIPPING_APP: 'side_menu.shipping_app',
  SIDE_MENU_INVENTORY: 'side_menu.inventory',
  SIDE_MENU_VERIFICATION: 'side_menu.verification',
  SIDE_MENU_RETURNS: 'side_menu.returns',
  SIDE_MENU_UNSOLD: 'side_menu.unsold',
  HEADER_APP_TITLE: 'header.app_title',
  HEADER_CLOSE_SESSION: 'header.close_session',
};

export { PREFIX, I18N_MESSAGES };
