import React, { useCallback, useMemo } from 'react';
import { Icon, IconVariant, Text, TextVariant } from '@lookiero/react-ui-kit';
import 'ui/component-library/molecules/notification/notification.css';
import NotificationLevel from 'core/domain/model/notification/NotificationLevel';
import { NotificationProjection } from 'core/domain/model/notification/NotificationProjection';
import classNames from 'classnames';
import { ReactIntl } from '@lookiero/i18n.js';

const useNotificationVariant = (level: NotificationLevel): { icon: IconVariant; modifier: string } => {
  const { icon, modifier } = useMemo(() => {
    switch (level) {
      case NotificationLevel.ERROR:
        return { icon: IconVariant.ERROR_TRIANGLE, modifier: 'error' };
      case NotificationLevel.SUCCESS:
        return { icon: IconVariant.CHECK_FILLED, modifier: 'success' };
      default:
        return { icon: IconVariant.INFO, modifier: 'default' };
    }
  }, [level]);

  return { icon, modifier };
};

interface NotificationProps {
  readonly className?: string;
  readonly notification: NotificationProjection;
  readonly onCancel?: (notification: NotificationProjection) => void;
}
const Notification: React.FC<NotificationProps> = ({ className, notification, onCancel }: NotificationProps) => {
  const { icon, modifier } = useNotificationVariant(notification.level);
  const handleOnClick = useCallback(() => onCancel && onCancel(notification), [notification, onCancel]);

  const messages = {
    content: ReactIntl.useI18nMessage({ id: notification.content }),
    contentHeader: ReactIntl.useI18nMessage({ id: notification.contentHeader }),
  };

  return (
    <div className={classNames('notification', `notification--${modifier}`, className)}>
      <span className="notification__icon">
        <Icon variant={icon} />
      </span>
      <div className="notification__message-container">
        {notification.contentHeader && <Text variant={TextVariant.HEADING_3}>{messages.contentHeader}</Text>}
        <Text variant={TextVariant.BODY_SMALL}>{messages.content}</Text>
      </div>
      {onCancel && (
        <div className="notification__cancel" onClick={handleOnClick}>
          <Icon variant={IconVariant.CLOSE} />
        </div>
      )}
    </div>
  );
};

export default Notification;
