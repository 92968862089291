import { DomainEvent } from '@lookiero/messaging.js';
import Route from 'core/domain/model/routeList/write/Route';

class RoutesRemoved extends DomainEvent {
  readonly routes: Route[];

  public constructor(routes: Route[]) {
    super();
    this.routes = routes;
  }

  /* istanbul ignore next */
  public messageName(): string {
    return 'RoutesRemoved';
  }
}

export default RoutesRemoved;
