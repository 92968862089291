import { v4 as uuid } from 'uuid';
import { CommandBus, ProcessManager } from '@lookiero/messaging.js';

import CreateNotification from 'core/application/notification/createNotification/CreateNotification';
import NotificationLevel from 'core/domain/model/notification/NotificationLevel';
import PalletSendFinishFailed from './PalletSendFinishFailed';
import { I18N_MESSAGES, PREFIX } from 'shared/i18n/shipping';

class PalletSendFinishFailedListener implements ProcessManager<PalletSendFinishFailed> {
  commandBus!: CommandBus;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async process(event: PalletSendFinishFailed): Promise<void> {
    const command = new CreateNotification(
      uuid(),
      NotificationLevel.ERROR,
      `${PREFIX}${I18N_MESSAGES.NOTIFICATION_SEND_FINISH_PALLET_FAILED}`,
      `${PREFIX}${I18N_MESSAGES.NOTIFICATION_SEND_PALLETS_FAILED_TITLE}`,
    );

    this.commandBus.dispatch(command);
  }
}

export default PalletSendFinishFailedListener;
