import { CommandBus, ProcessManager } from '@lookiero/messaging.js';
import EmployeeAuthenticated from './EmployeeAuthenticated';
import CreateEmployee from 'core/application/employee/create/CreateEmployee';

class CreateEmployeeWhenAuthenticated implements ProcessManager<EmployeeAuthenticated> {
  private readonly commandBus!: CommandBus;

  public async process(employee: EmployeeAuthenticated): Promise<void> {
    this.commandBus.dispatch(new CreateEmployee({ ...employee }));
  }
}

export default CreateEmployeeWhenAuthenticated;
