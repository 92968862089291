import { QueryHandler } from '@lookiero/messaging.js';
import OrderReturnReader from 'core/domain/model/orderReturn/read/OrderReturnReader';
import { OrderReturnView } from 'core/domain/model/orderReturn/read/OrderReturnView';
import FindOrderReturn from './FindOrderReturn';

class FindOrderReturnHandler implements QueryHandler<FindOrderReturn> {
  private readonly repository: OrderReturnReader;

  public constructor(repository: OrderReturnReader) {
    this.repository = repository;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async handle(query: FindOrderReturn): Promise<OrderReturnView> {
    return await this.repository.findOrderReturn(query);
  }
}

export default FindOrderReturnHandler;
