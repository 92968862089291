import DomainError from 'shared/domain/error/DomainError';
import ErrorCode from 'shared/domain/error/ErrorCode';
import { I18N_MESSAGES } from 'shared/i18n/picking';

class RouteNotFoundToPrintError extends DomainError {
  public constructor() {
    super(
      ErrorCode.ROUTE_NOT_FOUND_TO_PRINT,
      I18N_MESSAGES.ROUTE_NOT_FOUND_TO_PRINT_ERROR,
      RouteNotFoundToPrintError.name,
    );
  }
}

export default RouteNotFoundToPrintError;
