import EmployeeWriter from 'core/domain/model/employee/write/EmployeeWriter';
import LogoutEmployee from 'core/application/employee/logout/LogoutEmployee';
import Employee from 'core/domain/model/employee/write/Employee';
import { CommandHandler, EventBus } from '@lookiero/messaging.js';

class LogoutEmployeeHandler implements CommandHandler<LogoutEmployee> {
  private readonly repository: EmployeeWriter;
  eventBus!: EventBus;

  public constructor(repository: EmployeeWriter) {
    this.repository = repository;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async execute(commmand: LogoutEmployee): Promise<void> {
    const employee = Employee.logout();
    this.repository.invalidateCurrentEmployee();
    this.eventBus.publish([...employee.recordedEvents()]);
  }
}

export default LogoutEmployeeHandler;
