import { DomainEvent } from '@lookiero/messaging.js';

class NotificationCreated extends DomainEvent {
  public notificationId: string;

  public constructor(notificationId: string) {
    super();
    this.notificationId = notificationId;
  }

  /* istanbul ignore next */
  public messageName(): string {
    return 'NotificationCreated';
  }
}

export default NotificationCreated;
