import { v4 as uuid } from 'uuid';
import { CommandBus, ProcessManager } from '@lookiero/messaging.js';
import CreateNotification from 'core/application/notification/createNotification/CreateNotification';
import NotificationLevel from 'core/domain/model/notification/NotificationLevel';
import InventoryFinalized from './InventoryFinalized';
import { I18N_MESSAGES, PREFIX } from 'shared/i18n/inventory';

class InventoryFinalizedListener implements ProcessManager<InventoryFinalized> {
  commandBus!: CommandBus;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async process(event: InventoryFinalized): Promise<void> {
    const notificationCommand = new CreateNotification(
      uuid(),
      NotificationLevel.SUCCESS,
      PREFIX + I18N_MESSAGES.NOTIFICATION_INVENTORY_FINALIZED,
      PREFIX + I18N_MESSAGES.NOTIFICATION_INVENTORY_FINALIZED_TITLE,
    );
    this.commandBus.dispatch(notificationCommand);
  }
}

export default InventoryFinalizedListener;
