import { QueryHandler } from '@lookiero/messaging.js';
import FindPallet from './FindPallet';
import { PalletView } from 'core/domain/model/pallet/read/PalletView';
import PalletReader from 'core/domain/model/pallet/read/PalletReader';

class FindPalletHandler implements QueryHandler<FindPallet> {
  private readonly repository: PalletReader;

  public constructor(repository: PalletReader) {
    this.repository = repository;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async handle(query: FindPallet): Promise<PalletView | undefined> {
    return this.repository.findPallet(query.palletNumber);
  }
}

export default FindPalletHandler;
