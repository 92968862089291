import DomainError from 'shared/domain/error/DomainError';
import ErrorCode from 'shared/domain/error/ErrorCode';
import { I18N_MESSAGES } from 'shared/i18n/verification';

class OrderReferenceInvalidError extends DomainError {
  public constructor() {
    super(
      ErrorCode.ORDER_REFERENCE_INVALID,
      I18N_MESSAGES.IDENTIFY_ORDER_REFERENCE_ERROR,
      OrderReferenceInvalidError.name,
    );
  }
}

export default OrderReferenceInvalidError;
