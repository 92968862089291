import InventoryLocationInvalidError from 'core/domain/model/inventory/InventoryLocationInvalidError';
import Inventory from 'core/domain/model/inventory/write/Inventory';
import InventoryReferenceInvalidError from 'core/domain/model/inventory/write/InventoryReferenceInvalidError';
import InventoryWriter from 'core/domain/model/inventory/write/InventoryWriter';
import HttpClient, { HttpClientResponseError, HttpErrorCodes } from 'shared/domain/delivery/HttpClient';

class HttpInventoryWriter implements InventoryWriter {
  private readonly httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public inventoryReference(inventory: Inventory): Promise<void> {
    const body = {
      barcode: inventory.reference?.barcode,
      quantityToAdd: inventory.reference?.quantity,
    };

    return this.httpClient
      .patch<void>(`/v2/inventory/locations/${inventory.location.value}`, body)
      .catch((error: HttpClientResponseError) => {
        if (error.status === HttpErrorCodes.BAD_REQUEST || error.status === HttpErrorCodes.NOT_FOUND) {
          throw new InventoryReferenceInvalidError();
        } else {
          throw error;
        }
      });
  }

  public finalizeInventory(inventory: Inventory): Promise<void> {
    const body = {};
    return this.httpClient
      .post<void>(`/v2/inventory/locations/${inventory.location.value}/finalize`, body)
      .catch(this.catchInventoryBadRequest);
  }

  public removeInventory(inventory: Inventory): Promise<void> {
    return this.httpClient
      .delete<void>(`/v2/inventory/locations/${inventory.location.value}`)
      .catch(this.catchInventoryBadRequest);
  }

  private catchInventoryBadRequest(error: HttpClientResponseError): void {
    if (error.status === HttpErrorCodes.BAD_REQUEST) {
      throw new InventoryLocationInvalidError();
    } else {
      throw error;
    }
  }
}

export default HttpInventoryWriter;
