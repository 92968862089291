import AggregateRoot from 'shared/domain/AggregateRoot';
import ReferenceQuantity from './ReferenceQuantity';
import Location from './Location';
import ReferenceInventoried from './event/referenceInventoried/ReferenceInventoried';
import InventoryFinalized from './event/inventoryFinalized/InventoryFinalized';
import InventoryRemoved from './event/inventoryRemoved/InventoryRemoved';
class Inventory extends AggregateRoot {
  readonly location: Location;
  readonly reference?: ReferenceQuantity;

  private constructor(location: Location, reference?: ReferenceQuantity) {
    super();
    this.location = location;
    this.reference = reference;
  }

  public static inventoryReference(location: Location, reference: ReferenceQuantity): Inventory {
    const inventory = new Inventory(location, reference);
    inventory.record(new ReferenceInventoried(location, reference));
    return inventory;
  }

  public static finalizeInventory(location: Location): Inventory {
    const inventory = new Inventory(location);
    inventory.record(new InventoryFinalized(location));
    return inventory;
  }

  public static removeInventory(location: Location): Inventory {
    const inventory = new Inventory(location);
    inventory.record(new InventoryRemoved(location));
    return inventory;
  }
}

export default Inventory;
