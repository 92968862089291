import { v4 as uuid } from 'uuid';
import { CommandBus, ProcessManager } from '@lookiero/messaging.js';
import CreateNotification from 'core/application/notification/createNotification/CreateNotification';
import NotificationLevel from 'core/domain/model/notification/NotificationLevel';
import InventoryReferenceFailed from './InventoryReferenceFailed';
import { I18N_MESSAGES, PREFIX } from 'shared/i18n/inventory';

class InventoryReferenceFailedListener implements ProcessManager<InventoryReferenceFailed> {
  commandBus!: CommandBus;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async process(event: InventoryReferenceFailed): Promise<void> {
    const command = new CreateNotification(
      uuid(),
      NotificationLevel.ERROR,
      PREFIX + I18N_MESSAGES.NOTIFICATION_INVENTORY_REFERENCE_FAILED,
      PREFIX + I18N_MESSAGES.NOTIFICATION_INVENTORY_REFERENCE_FAILED_TITLE,
    );

    this.commandBus.dispatch(command);
  }
}

export default InventoryReferenceFailedListener;
