import Credentials from 'core/domain/model/employee/write/Credentials';
import { Command } from '@lookiero/messaging.js';

class LoginEmployee extends Command {
  readonly credentials: Credentials;

  public constructor(credentials: Credentials) {
    super();
    this.credentials = credentials;
  }

  /* istanbul ignore next */
  protected messageName(): string {
    return 'LoginEmployee';
  }
}

export default LoginEmployee;
