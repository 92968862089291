class StoragePrinterReader {
  private static readonly PREPARER_PRINTER_KEY = 'preparer_printer';
  private static readonly RETURNS_PRINTER_KEY = 'returns_printer';

  private readonly storage: Storage;

  constructor(storage: Storage) {
    this.storage = storage;
  }

  findPreparerPrinter(): string | null {
    return this.storage.get(StoragePrinterReader.PREPARER_PRINTER_KEY);
  }

  findReturnsPrinter(): string | null {
    return this.storage.get(StoragePrinterReader.RETURNS_PRINTER_KEY);
  }
}

export default StoragePrinterReader;
