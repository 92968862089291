import { DomainEvent } from '@lookiero/messaging.js';
import Order from 'core/domain/model/pallet/write/Order';

class OrderAdded extends DomainEvent {
  readonly order: Order;

  public constructor(order: Order) {
    super();
    this.order = order;
  }

  /* istanbul ignore next */
  public messageName(): string {
    return 'OrderAdded';
  }
}

export default OrderAdded;
