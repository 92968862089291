import { CommandHandler, EventBus } from '@lookiero/messaging.js';
import RouteWriter from 'core/domain/model/route/write/RouteWriter';
import Route from 'core/domain/model/route/write/Route';
import AssignRouteToTrolley from './AssignRouteToTrolley';

class AssignRouteToTrolleyHandler implements CommandHandler<AssignRouteToTrolley> {
  private readonly routeWriter: RouteWriter;
  eventBus!: EventBus;

  constructor(routeWriter: RouteWriter) {
    this.routeWriter = routeWriter;
  }

  async execute(command: AssignRouteToTrolley): Promise<void> {
    const route = Route.assignRouteToTrolley(command.routeId, command.trolleyId);
    await this.routeWriter.assignRouteToTrolley(route);
    this.eventBus.publish([...route.recordedEvents()]);
  }
}

export default AssignRouteToTrolleyHandler;
