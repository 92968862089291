import { DomainEvent } from '@lookiero/messaging.js';
import NumberOfLabels from '../../NumberOfLabels';
import Order from '../../Order';
import Pallet from '../../Pallet';
import { ShippingType } from '../../ShippingType';

class PalletLabelsPrinted extends DomainEvent {
  readonly pallet: {
    readonly numberOfLabels?: NumberOfLabels;
    readonly shippingTypes?: ShippingType[];
    readonly palletId?: string;
    readonly order?: Order;
  };

  public constructor(pallet: Pallet) {
    super();
    this.pallet = { ...pallet };
  }

  /* istanbul ignore next */
  public messageName(): string {
    return 'PalletLabelsPrinted';
  }
}

export default PalletLabelsPrinted;
