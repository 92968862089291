import { v4 as uuid } from 'uuid';
import { CommandBus, ProcessManager } from '@lookiero/messaging.js';
import CreateNotification from 'core/application/notification/createNotification/CreateNotification';
import NotificationLevel from 'core/domain/model/notification/NotificationLevel';
import PalletsSent from './PalletsSent';

class PalletsSentListener implements ProcessManager<PalletsSent> {
  commandBus!: CommandBus;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async process(event: PalletsSent): Promise<void> {
    const notificationCommand = new CreateNotification(uuid(), NotificationLevel.SUCCESS, 'Datos de envío registrados');
    this.commandBus.dispatch(notificationCommand);
  }
}

export default PalletsSentListener;
