import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { MessagingRoot } from '@lookiero/messaging.js';
import ErrorReporter from 'ui/views/errorReporter/ErrorReporter';
import ErrorBoundary from 'ui/views/root/ErrorBoundary/ErrorBoundary';
import { Loader } from '@lookiero/react-ui-kit';
import Logger from 'core/domain/model/logger/Logger';
import { EnvironmentProvider } from '../_behaviors/useEnvironment';
import Environment from 'shared/domain/environment/Environment';
import { EmployeeProvider } from 'ui/views/_behaviors/useGetCurrentEmployee';
import { ReactIntl } from '@lookiero/i18n.js';
import Routing from 'ui/_routing/Routing';
import { withProfiler } from '@sentry/react';

import './root.css';

interface RootProps {
  readonly MessagingRootComponent: MessagingRoot;
  readonly logger: Logger;
  readonly environment: Environment;
  readonly getI18nEndpoint: (locale: string) => string;
  readonly sentryLogger: Logger;
}

const Root: React.FC<RootProps> = ({ MessagingRootComponent, logger, environment, getI18nEndpoint, sentryLogger }) => {
  return (
    <EnvironmentProvider environment={environment}>
      <ReactIntl.I18n
        locale={environment.internationalization.defaultLocale}
        endpoint={getI18nEndpoint}
        loader={<Loader />}
      >
        <ErrorBoundary logger={sentryLogger} fallback={ErrorReporter}>
          <ErrorBoundary logger={logger} fallback={ErrorReporter}>
            <BrowserRouter>
              <MessagingRootComponent>
                <EmployeeProvider>
                  <React.Suspense fallback={<Loader />}>
                    <div className="root">
                      <Routing />
                    </div>
                  </React.Suspense>
                </EmployeeProvider>
              </MessagingRootComponent>
            </BrowserRouter>
          </ErrorBoundary>
        </ErrorBoundary>
      </ReactIntl.I18n>
    </EnvironmentProvider>
  );
};

export default withProfiler(Root);
