import { Query } from '@lookiero/messaging.js';

type FindOrderParameter = {
  readonly barcodes: string[];
};

class FindOrder extends Query {
  public readonly barcodes: string[];

  public constructor({ barcodes }: FindOrderParameter) {
    super();
    this.barcodes = barcodes;
  }

  /* istanbul ignore next */
  protected messageName(): string {
    return 'FindOrder';
  }
}

export default FindOrder;
