import { CommandHandler, EventBus } from '@lookiero/messaging.js';
import RemoveOrders from './RemoveOrders';
import PalletWriter from 'core/domain/model/pallet/write/PalletWriter';
import Order from 'core/domain/model/pallet/write/Order';
import Pallet from 'core/domain/model/pallet/write/Pallet';

class RemoveOrdersHandler implements CommandHandler<RemoveOrders> {
  private readonly palletWriter: PalletWriter;
  eventBus!: EventBus;

  public constructor(palletWriter: PalletWriter) {
    this.palletWriter = palletWriter;
  }

  public async execute({ palletId, publicOrderIds: orderIds }: RemoveOrders): Promise<void> {
    const orders = orderIds.map((id) => Order.createOrder(id));
    const pallet = Pallet.removeOrders(palletId, orders);

    await this.palletWriter.removeOrders(pallet);
    this.eventBus.publish([...pallet.recordedEvents()]);
  }
}

export default RemoveOrdersHandler;
