import { BarcodeEan13 } from 'shared/domain/model/BarcodeEan13';

class InventoryView {
  readonly location: LocationView;
  readonly references: ReferenceQuantityItemView[];

  constructor(obj: { location: string; references: ReferenceQuantityItemView[] }) {
    this.location = obj.location;
    this.references = obj.references;
  }

  getTotalReferences(): number {
    let totalReferences = 0;
    this.references.forEach((reference) => (totalReferences += reference.quantity));
    return totalReferences;
  }
}

export interface ReferenceQuantityItemView {
  readonly reference: ReferenceItemView;
  readonly quantity: number;
}

interface ReferenceItemView {
  readonly id: string;
  readonly barcode: BarcodeEan13;
}

export type LocationView = string;

export default InventoryView;
