import React, { createContext, FC, ReactNode, useContext } from 'react';
import { invariant } from 'ts-invariant';
import Environment from 'shared/domain/environment/Environment';

const EnvironmentContext = createContext<Environment>({} as Environment);

interface EnvironmentContextProviderProps {
  readonly environment: Environment;
  readonly children: ReactNode;
}

const EnvironmentProvider: FC<EnvironmentContextProviderProps> = ({
  environment,
  children,
}: EnvironmentContextProviderProps) => (
  <EnvironmentContext.Provider value={environment}>{children}</EnvironmentContext.Provider>
);

const useEnvironment = (): Environment => {
  const environment = useContext<Environment>(EnvironmentContext);

  invariant(
    environment,
    'Your are trying to use the useEnvironment hook without wrapping your app with the <EnvironmentProvider>.',
  );

  return environment;
};

export { EnvironmentProvider };

export default useEnvironment;
