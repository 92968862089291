import { DomainEvent } from '@lookiero/messaging.js';

import Reference from '../../ReferenceQuantity';
import Location from '../../Location';

class ReferenceInventoried extends DomainEvent {
  readonly location: Location;
  readonly reference: Reference;

  public constructor(location: Location, reference: Reference) {
    super();
    this.location = location;
    this.reference = reference;
  }

  /* istanbul ignore next */
  public messageName(): string {
    return 'ReferenceInventoried';
  }
}

export default ReferenceInventoried;
