import { Query } from '@lookiero/messaging.js';

class FindCouriers extends Query {
  /* istanbul ignore next */
  protected messageName(): string {
    return 'FindCouriers';
  }
}

export default FindCouriers;
