import { AggregateRoot } from '@lookiero/messaging.js';
import ErrorThrown from './ErrorThrown';

class CoreError extends AggregateRoot {
  private readonly error: Error;

  private constructor(error: Error) {
    super();
    this.error = error;
  }

  public static throw(error: Error): CoreError {
    const instance = new CoreError(error);
    instance.record(new ErrorThrown(error));

    return instance;
  }
}

export default CoreError;
