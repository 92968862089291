import React, { createContext, ReactNode, useContext } from 'react';
import { DomainEvent, useAsk, useDispatch } from '@lookiero/messaging.js';
import GetCurrentEmployee from 'core/application/employee/getCurrentEmployee/GetCurrentEmployee';
import EmployeeView from 'core/domain/model/employee/read/EmployeeView';
import EmployeeLoggedIn from 'core/domain/model/employee/write/event/EmployeeLoggedIn';
import EmployeeLoggedOut from 'core/domain/model/employee/write/event/EmployeeLoggedOut';
import ThrowError from 'shared/application/error/throwError/ThrowError';

const EmployeeViewContext = createContext<EmployeeView | undefined>(undefined);

interface EmployeeViewContextProviderProps {
  readonly children: ReactNode;
}

export const EmployeeProvider: React.FC<EmployeeViewContextProviderProps> = ({
  children,
}: EmployeeViewContextProviderProps) => {
  const dispatch = useDispatch();
  const { data } = useAsk<EmployeeView, Error>({
    query: new GetCurrentEmployee(),
    invalidation: (event: DomainEvent) => event instanceof EmployeeLoggedIn || event instanceof EmployeeLoggedOut,
    options: { staleTime: Infinity, onError: (error: Error) => dispatch(new ThrowError(error)) },
  });

  return <EmployeeViewContext.Provider value={data}>{children}</EmployeeViewContext.Provider>;
};

export const useGetCurrentEmployee = (): EmployeeView | undefined => {
  return useContext<EmployeeView | undefined>(EmployeeViewContext);
};
