import DomainError from 'shared/domain/error/DomainError';
import ErrorCode from 'shared/domain/error/ErrorCode';
import { I18N_MESSAGES } from 'shared/i18n/picking';

class RouteExistingWithTrolleyError extends DomainError {
  public constructor() {
    super(
      ErrorCode.ROUTE_EXISTING_WITH_TROLLEY,
      I18N_MESSAGES.ROUTE_EXISTING_WITH_TROLLEY_ERROR,
      RouteExistingWithTrolleyError.name,
    );
  }
}

export default RouteExistingWithTrolleyError;
