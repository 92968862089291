import { DomainEvent } from '@lookiero/messaging.js';
import Printer from 'core/domain/model/printer/write/Printer';

class PrinterAssigned extends DomainEvent {
  readonly printer: Printer;

  public constructor(printer: Printer) {
    super();
    this.printer = printer;
  }

  /* istanbul ignore next */
  public messageName(): string {
    return 'PrinterAssigned';
  }
}

export default PrinterAssigned;
