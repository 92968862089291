import { CommandHandler, EventBus } from '@lookiero/messaging.js';
import CreateEmployee from './CreateEmployee';
import EmployeeWriter from 'core/domain/model/employee/write/EmployeeWriter';
import Employee from 'core/domain/model/employee/write/Employee';

class CreateEmployeeHandler implements CommandHandler<CreateEmployee> {
  private readonly repository: EmployeeWriter;
  eventBus!: EventBus;

  constructor(repository: EmployeeWriter) {
    this.repository = repository;
  }

  public async execute(employeeToCreate: CreateEmployee): Promise<void> {
    const employee = Employee.create({ ...employeeToCreate });
    this.repository.save(employee);
    this.eventBus.publish([...employee.recordedEvents()]);
  }
}

export default CreateEmployeeHandler;
