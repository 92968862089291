const PREFIX = 'route.';

const I18N_MESSAGES = {
  TABS_UNASSIGNED_ROUTES: 'tabs.unassigned_routes',
  TABS_ASSIGNED_ROUTES: 'tabs.assigned_routes',
  TABS_PLANNING_ROUTES: 'tabs.planning_routes',
  TABS_PLANNED_ROUTES: 'tabs.planned_routes',

  ROUTE_LIST_HEADER_REMOVE_BUTTON: 'route_list_header.remove_button',
  ROUTE_LIST_HEADER_ID: 'route_list_header.id',
  ROUTE_LIST_HEADER_DISTANCE: 'route_list_header.distance',
  ROUTE_LIST_HEADER_TYPE: 'route_list_header.type',
  ROUTE_LIST_HEADER_ORDERS: 'route_list_header.orders',
  ROUTE_LIST_HEADER_PICKER_ID: 'route_list_header.picker_id',
  ROUTE_LIST_HEADER_START_DATE: 'route_list_header.start_date',
  ROUTE_LIST_HEADER_TROLLEY: 'route_list_header.trolley',

  REMOVE_MODAL_TITLE: 'remove_modal.title',
  REMOVE_MODAL_CONTENT: 'remove_modal.content',
  REMOVE_MODAL_ENSURE_ACTION: 'remove_modal.ensure_action',
  REMOVE_MODAL_CANCEL: 'remove_modal.cancel',
  REMOVE_MODAL_CONFIRM: 'remove_modal.confirm',

  REMOVE_ERROR_MODAL_TITLE: 'remove_error_modal.title',
  REMOVE_ERROR_MODAL_REMOVED: 'remove_error_modal.removed',
  REMOVE_ERROR_MODAL_UNREMOVED: 'remove_error_modal.unremoved',
  REMOVE_ERROR_MODAL_OKAY: 'remove_error_modal.okay',

  ROUTE_LIST_REMOVED_INVALID_ERROR: 'route_list.route_removed_invalid_error',

  NOTIFICATION_ROUTES_REMOVED: 'notification.routes_removed',

  ROUTE_LIST_FILTERS_PICKER: 'route_list_filters.picker',
  ROUTE_LIST_FILTERS_TYPE: 'route_list_filters.type',

  ROUTE_LIST_TYPES_SIZE_CHANGE: 'route_list_types.size_change',
  ROUTE_LIST_TYPES_DIRECT_BUY: 'route_list_types.direct_buy',
  ROUTE_LIST_TYPES_BOX: 'route_list_types.box',

  ORDER_ITEM_TITLE: 'order_item.title',
  ORDER_ITEM_REFERENCE: 'order_item.reference',
  ORDER_ITEM_BRAND: 'order_item.brand',
  ORDER_ITEM_COLOR: 'order_item.color',
  ORDER_ITEM_SIZE: 'order_item.size',
  ORDER_ITEM_LOCATION: 'order_item.location',
  ORDER_ITEM_BOX: 'order_item.box',
  ORDER_ITEM_STOCK: 'order_item.stock',
  ORDER_ITEM_MISSING_BUTTON: 'order_item.missing_button',
  NEXT_ORDER_ITEM_TEXT: 'next_order_item.text',
};

export { PREFIX, I18N_MESSAGES };
