import AggregateRoot from 'shared/domain/AggregateRoot';
import OrderReturnReferencePrinted from './event/orderReturnReferencePrinted/OrderReturnReferencePrinted';

export class OrderReturnReference extends AggregateRoot {
  readonly id: string;

  private constructor(referenceId: string) {
    super();
    this.id = referenceId;
  }

  static createReference(referenceId: string): OrderReturnReference {
    return new OrderReturnReference(referenceId);
  }

  static printOrderReturnReference(referenceId: string): OrderReturnReference {
    const orderReturnReference = new OrderReturnReference(referenceId);
    orderReturnReference.record(new OrderReturnReferencePrinted());
    return orderReturnReference;
  }
}
