class ReferenceQuantity {
  readonly barcode: string;
  readonly quantity: number;

  private constructor(barcode: string, quantity: number) {
    this.barcode = barcode;
    this.quantity = quantity;
  }

  public static createReferenceQuantity(barcode: string, quantity: number): ReferenceQuantity {
    return new ReferenceQuantity(barcode, quantity);
  }
}

export default ReferenceQuantity;
