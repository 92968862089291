import { QueryHandler } from '@lookiero/messaging.js';
import OrderReader from 'core/domain/model/order/read/OrderReader';
import { OrderView } from 'core/domain/model/order/read/OrderView';
import GetUnsoldOrders from './GetUnsoldOrders';

class GetUnsoldOrdersHandler implements QueryHandler<GetUnsoldOrders> {
  private readonly orderReader: OrderReader;

  public constructor(orderReader: OrderReader) {
    this.orderReader = orderReader;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public handle(query: GetUnsoldOrders): Promise<OrderView[]> {
    return this.orderReader.getUnsoldOrders();
  }
}

export default GetUnsoldOrdersHandler;
