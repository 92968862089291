import { Command } from '@lookiero/messaging.js';

class AssignPreparerPrinter extends Command {
  public readonly printerLine: string;

  public constructor(printerLine: string) {
    super();
    this.printerLine = printerLine;
  }

  /* istanbul ignore next */
  protected messageName(): string {
    return 'AssignPreparerPrinter';
  }
}

export default AssignPreparerPrinter;
