import RouteCountView from 'core/domain/model/route/read/RouteCountView';
import { QueryHandler } from '@lookiero/messaging.js';
import RouteReader from 'core/domain/model/route/read/RouteReader';
import FindRoutesCount from './FindRoutesCount';
class FindRoutesCountHandler implements QueryHandler<FindRoutesCount> {
  private readonly reader: RouteReader;
  public constructor(reader: RouteReader) {
    this.reader = reader;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async handle(query: FindRoutesCount): Promise<RouteCountView> {
    return this.reader.findRoutesCount();
  }
}
export default FindRoutesCountHandler;
