import { QueryHandler } from '@lookiero/messaging.js';
import FindInventory from './FindInventory';
import InventoryReader from 'core/domain/model/inventory/read/InventoryReader';
import InventoryView from 'core/domain/model/inventory/read/InventoryView';

class FindInventoryHandler implements QueryHandler<FindInventory> {
  private readonly repository: InventoryReader;

  public constructor(repository: InventoryReader) {
    this.repository = repository;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async handle(query: FindInventory): Promise<InventoryView | undefined> {
    return this.repository.findInventory(query.location);
  }
}

export default FindInventoryHandler;
