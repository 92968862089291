import Location from '../../inventory/write/Location';
import { RouteType } from './RouteType';

class RouteView {
  private readonly PICKING_BOX_LOCATION_REGEXP = /^[C|c]\.(\d{2})\.\d{2}\.C?\d{2}$/;
  readonly id: string;
  readonly routeForType: RouteType;
  readonly routeWorkOrders: RouteWorkOrderView[];
  readonly pickerId: number;
  readonly trolley: string;
  readonly startDate: string;
  readonly score: ScoreView;
  readonly workOrderReferenceLocations: RouteWorkOrderReferenceLocationView[];
  readonly currentIndex: number;

  constructor({
    id,
    routeForType,
    routeWorkOrders,
    pickerId,
    trolley,
    startDate,
    score,
    workOrderReferenceLocations,
    currentIndex,
  }: {
    id: string;
    routeForType: RouteType;
    routeWorkOrders: RouteWorkOrderView[];
    pickerId: number;
    trolley: string;
    startDate: string;
    score: ScoreView;
    workOrderReferenceLocations: RouteWorkOrderReferenceLocationView[];
    currentIndex: number;
  }) {
    this.id = id;
    this.routeForType = routeForType;
    this.routeWorkOrders = routeWorkOrders;
    this.pickerId = pickerId;
    this.trolley = trolley;
    this.startDate = startDate;
    this.score = score;
    this.workOrderReferenceLocations = workOrderReferenceLocations;
    this.currentIndex = currentIndex;
  }

  getUniquePublicOrderIds(): string[] {
    const publicOrderIdsMap = new Map(
      this.routeWorkOrders.map((routeWorkOrder): [string, string] => [
        routeWorkOrder.workOrderId,
        routeWorkOrder.publicOrderId,
      ]),
    );
    return this.workOrderReferenceLocations
      .map((referenceLocation) => publicOrderIdsMap.get(referenceLocation.workOrderId))
      .filter((value, index, self) => value !== undefined && self.indexOf(value) === index) as string[];
  }

  getCurrentPickingBoxLocationToNumber(): number {
    const pickingBoxLocation = this.workOrderReferenceLocations[this.currentIndex].pickingBoxLocation;
    const match = this.PICKING_BOX_LOCATION_REGEXP.exec(pickingBoxLocation);
    return match && match.length > 0 ? +match[1] : 0;
  }
}

export interface ScoreView {
  readonly meter: number;
}

export interface RouteWorkOrderView {
  readonly publicOrderId: string;
  readonly workOrderId: string;
}

export interface RouteWorkOrderReferenceLocationView {
  readonly workOrderId: string;
  readonly pickingBoxLocation: string;
  readonly referenceId: string;
  readonly completed: boolean;
  readonly location: Location;
}

export type TrolleyIdView = string;

export default RouteView;
