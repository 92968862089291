import { QueryHandler } from '@lookiero/messaging.js';

import EmployeeReader from 'core/domain/model/employee/read/EmployeeReader';
import GetCurrentEmployee from './GetCurrentEmployee';
import EmployeeView from 'core/domain/model/employee/read/EmployeeView';

class GetCurrentEmployeeHandler implements QueryHandler<GetCurrentEmployee> {
  private readonly repository: EmployeeReader;

  public constructor(repository: EmployeeReader) {
    this.repository = repository;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async handle(query: GetCurrentEmployee): Promise<EmployeeView | undefined> {
    return this.repository.getCurrentEmployeeView();
  }
}

export default GetCurrentEmployeeHandler;
