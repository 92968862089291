import React, { FC } from 'react';
import { Navigate, generatePath } from 'react-router-dom';
import Routes from './routes';
import useIsEmployeeLoggedIn from 'ui/views/_behaviors/useIsEmployeeLoggedIn';

interface AuthenticationMiddlewareProps {
  children: JSX.Element;
}

const AuthenticationMiddleware: FC<AuthenticationMiddlewareProps> = ({ children }) => {
  const isEmployeeStillLoggedIn = useIsEmployeeLoggedIn();

  return isEmployeeStillLoggedIn === undefined ? null : isEmployeeStillLoggedIn ? (
    children
  ) : (
    <Navigate to={generatePath(Routes.LOGIN)} />
  );
};

export default AuthenticationMiddleware;
