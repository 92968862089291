const PREFIX = 'returns.';

const I18N_MESSAGES = {
  TABS_PENDING_RETURNS: 'tabs.pending_returns',

  PENDING_RETURNS_ORDER_INFO_LABEL: 'pending_returns.order_info_label',
  PENDING_RETURNS_ORDER_INFO_STATUS: 'pending_returns.order_info_status',
  PENDING_RETURNS_ORDER_INFO_DRAFT: 'pending_returns.order_info_draft',
  PENDING_RETURNS_ORDER_INFO_FINISHED: 'pending_returns.order_info_finished',
  PENDING_RETURNS_ORDER_INFO_COUNTRY: 'pending_returns.order_info_country',
  PENDING_RETURNS_ORDER_INFO_ORDER: 'pending_returns.order_info_order',

  PENDING_RETURNS_COURIER_ORDER_ID_TITLE: 'pending_returns.courier_order_id_title',
  PENDING_RETURNS_COURIER_ORDER_ID_INPUT_SCANNER_LABEL: 'pending_returns.courier_order_id_input_scanner_label',
  PENDING_RETURNS_COURIER_ORDER_ID_INPUT_SCANNER_PLACEHOLDER:
    'pending_returns.courier_order_id_input_scanner_placeholder',
  PENDING_RETURNS_ORDER_ID_TITLE: 'pending_returns.order_id_input_title',
  PENDING_RETURNS_ORDER_ID_INPUT_SCANNER_LABEL: 'pending_returns.order_id_input_scanner_label',
  PENDING_RETURNS_ORDER_ID_INPUT_SCANNER_PLACEHOLDER: 'pending_returns.order_id_input_scanner_placeholder',
  PENDING_RETURNS_ORDER_FINISH_RETURN_BUTTON: 'pending_returns.finish_return_button',
  PENDING_RETURNS_ORDER_BACT_TO_SCAN_BUTTON: 'pending_returns.back_to_scan_button',
  PENDING_RETURNS_REFERENCE_INPUT_SCANNER_LABEL: 'pending_returns.reference_input_scanner_label',
  PENDING_RETURNS_REFERENCE_INPUT_SCANNER_PLACEHOLDER: 'pending_returns.reference_input_scanner_placeholder',

  PENDING_RETURNS_LEAVE_CONFIRM_MODAL_TITLE: 'pending_returns.leave_confirm_modal_title',
  PENDING_RETURNS_LEAVE_CONFIRM_MODAL_BODY: 'pending_returns.leave_confirm_modal_body',
  PENDING_RETURNS_LEAVE_CONFIRM_MODAL_OK: 'pending_returns.leave_confirm_modal_ok',
  PENDING_RETURNS_LEAVE_CONFIRM_MODAL_CANCEL: 'pending_returns.leave_confirm_modal_cancel',

  PENDING_RETURNS_FINISH_CONFIRM_MODAL_TITLE: 'pending_returns.finish_confirm_modal_title',
  PENDING_RETURNS_FINISH_CONFIRM_MODAL_BODY: 'pending_returns.finish_confirm_modal_body',
  PENDING_RETURNS_FINISH_CONFIRM_MODAL_OK: 'pending_returns.finish_confirm_modal_ok',
  PENDING_RETURNS_FINISH_CONFIRM_MODAL_CANCEL: 'pending_returns.finish_confirm_modal_cancel',

  ORDER_RETURN_VIEW_NOT_FOUND_ERROR: 'order_return_view.not_found_error',
  FINISH_ORDER_RETURN_ERROR: 'order_return.finish_order_return_error',
  REFERENCE_RETURN_VIEW_NOT_FOUND_ERROR: 'reference_return_view.not_found_error',
  REFERENCE_RETURN_VIEW_NOT_RETURNED_ERROR: 'reference_return_view.not_returned_error',
  PRINT_ORDER_RETURN_REFERENCE_FAILED_ERROR: 'notification.print_order_return_reference_failed_error',

  ORDER_DETAIL_TABLE_HEADER_REFERENCE: 'order_detail_table_header.reference',
  ORDER_DETAIL_TABLE_HEADER_BARCODE: 'order_detail_table_header.barcode',
  ORDER_DETAIL_TABLE_HEADER_BRAND: 'order_detail_table_header.brand',

  NOTIFICATION_FINISH_ORDER_RETURN: 'notification.finish_order_return',

  ORDER_HEADER_LABEL: 'order_header.label',
  ORDER_HEADER_PRINTER_LINE_LABEL: 'order_header.printer_line_label',

  NOTIFICATION_ORDER_RETURN_REFERENCE_PRINTED: 'notification.order_return_reference_printed',

  TOOLTIP_HOTKEY_SELECT_PRODUCT: 'tooltip_hotkey.select_product',
  TOOLTIP_HOTKEY_SPACE: 'tooltip_hotkey.space',
  TOOLTIP_HOTKEY_ZOOM: 'tooltip_hotkey.zoom',
  TOOLTIP_HOTKEY_COMPLETING_RETURN: 'tooltip_hotkey.completing_return',
  TOOLTIP_HOTKEY_ESCAPE_RETURN: 'tooltip_hotkey.escape_return',
};

export { PREFIX, I18N_MESSAGES };
