import Credentials from 'core/domain/model/employee/write/Credentials';
import HttpClient from 'shared/domain/delivery/HttpClient';
import { JwtToken } from '../JwtToken';
import { JwtPayload, jwtDecode } from 'jwt-decode';
import JwtAuthenticatorResult from './JwtAuthenticatorResult';

export interface EmployeeJwtPayload extends JwtPayload {
  roles: string;
  sub: string;
}
class Authenticator {
  private readonly httpClient: HttpClient;
  private readonly authEndpoint: string;

  constructor(httpClient: HttpClient, authEndpoint: string) {
    this.httpClient = httpClient;
    this.authEndpoint = authEndpoint;
  }

  private readonly getEmployeeRolesFromAuthenticationToken = (authToken: EmployeeJwtPayload): string[] => {
    return authToken.roles.split(',');
  };

  public async auth(credentials: Credentials): Promise<JwtAuthenticatorResult> {
    const authToken = await this.httpClient.post<JwtToken>(this.authEndpoint, { ...credentials }, 'text');
    const decodedToken = this.decodeToken(authToken);
    const roles = this.getRoles(decodedToken);
    const userId = this.getEmployeeId(decodedToken);

    return { userId, authToken, roles };
  }

  private decodeToken(token: JwtToken): EmployeeJwtPayload {
    return jwtDecode<EmployeeJwtPayload>(token);
  }

  private getRoles(decodedToken: EmployeeJwtPayload): string[] {
    return this.getEmployeeRolesFromAuthenticationToken(decodedToken);
  }

  private getEmployeeId(decodedToken: EmployeeJwtPayload): string {
    return decodedToken.sub;
  }
}

export default Authenticator;
