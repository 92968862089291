import DomainError from 'shared/domain/error/DomainError';
import ErrorCode from 'shared/domain/error/ErrorCode';
import { I18N_MESSAGES } from 'shared/i18n/picking';

class OrderViewsNotFoundError extends DomainError {
  public constructor() {
    super(ErrorCode.ORDER_VIEWS_NOT_FOUND, I18N_MESSAGES.ORDER_VIEWS_NOT_FOUND_ERROR, OrderViewsNotFoundError.name);
  }
}

export default OrderViewsNotFoundError;
