import { Query } from '@lookiero/messaging.js';
import { RouteItemCriteria } from '../../../domain/model/routeItem/read/RouteItemReader';

class GetRouteItem extends Query {
  public orderId: string;
  public orderItemId: string;
  public location: string;

  constructor({ orderId, orderItemId, location }: RouteItemCriteria) {
    super();
    this.orderId = orderId;
    this.orderItemId = orderItemId;
    this.location = location;
  }

  protected messageName(): string {
    return 'GetRouteItem';
  }
}

export default GetRouteItem;
