import RouteReader from 'core/domain/model/route/read/RouteReader';
import HttpClient, { HttpClientResponseError, HttpErrorCodes } from 'shared/domain/delivery/HttpClient';
import { RouteType } from 'core/domain/model/route/read/RouteType';
import RouteViewWithPreparedTrolleyNotFoundError from 'core/domain/model/route/RouteViewWithPreparedTrolleyNotFoundError';
import RouteView, { TrolleyIdView } from 'core/domain/model/route/read/RouteView';
import RouteViewNotFoundError from 'core/domain/model/route/read/RouteViewNotFoundError';
import { RouteStatusFilter } from 'core/domain/model/route/read/RouteStatusFilter';
import FindRouteQueryParams from './FindRouteQueryParams';
import RouteCountView from 'core/domain/model/route/read/RouteCountView';

class HttpRouteReader implements RouteReader {
  private readonly httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public findRoutes(pickerId: string, statusFilter: RouteStatusFilter, routeTypes: RouteType[]): Promise<RouteView[]> {
    const findRoutesEndpoint = '/v2/routes';

    const findRoutesQueryParams: Readonly<FindRouteQueryParams> = new FindRouteQueryParams(
      pickerId,
      statusFilter,
      routeTypes,
    );
    return this.httpClient
      .get<RouteView[]>(findRoutesEndpoint, findRoutesQueryParams)
      .then((response) => response.map((routeViewResponse) => new RouteView({ ...routeViewResponse })));
  }

  public findRoutesCount(): Promise<RouteCountView> {
    const findRoutesEndpoint = '/v2/routes/count';
    return this.httpClient.get<RouteCountView>(findRoutesEndpoint);
  }

  public findPreparedTrolley(trolleyId: TrolleyIdView): Promise<RouteView> {
    const findPreparedTrolleyEndpoint = `/v2/routes/trolleyId=${trolleyId}`;

    return this.httpClient
      .get<RouteView>(findPreparedTrolleyEndpoint)
      .then((response) => new RouteView({ ...response }))
      .catch((error: HttpClientResponseError) => {
        if (error.status) {
          throw new RouteViewWithPreparedTrolleyNotFoundError();
        } else {
          throw error;
        }
      });
  }

  findPreparerRoute(preparerId: string): Promise<RouteView> {
    return this.httpClient
      .get<RouteView>(`/v2/routes/preparerId=${preparerId}`)
      .then((response) => new RouteView({ ...response }))
      .catch((error: HttpClientResponseError) => {
        if (error.status === HttpErrorCodes.NOT_FOUND || error.body?.code === 'D-0001') {
          throw new RouteViewNotFoundError();
        } else {
          throw error;
        }
      });
  }

  findRouteById(routeId: string): Promise<RouteView> {
    const findRouteByIdEndpoint = `/v2/routes/${routeId}`;

    return this.httpClient
      .get<RouteView>(findRouteByIdEndpoint)
      .then((response) => new RouteView({ ...response }))
      .catch((error: HttpClientResponseError) => {
        if (error.status) {
          throw new RouteViewNotFoundError();
        } else {
          throw error;
        }
      });
  }
}

export default HttpRouteReader;
