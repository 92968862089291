import { QueryHandler } from '@lookiero/messaging.js';

import CheckCurrentEmployeeIsLoggedIn from './CheckCurrentEmployeeIsLoggedIn';
import EmployeeReader from 'core/domain/model/employee/read/EmployeeReader';

class CheckCurrentEmployeeIsLoggedInHandler implements QueryHandler<CheckCurrentEmployeeIsLoggedIn> {
  private readonly repository: EmployeeReader;

  public constructor(repository: EmployeeReader) {
    this.repository = repository;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async handle(query: CheckCurrentEmployeeIsLoggedIn): Promise<boolean> {
    return this.repository.isStillLoggedIn();
  }
}

export default CheckCurrentEmployeeIsLoggedInHandler;
