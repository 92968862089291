/* istanbul ignore file */

enum LoggerLayer {
  UI = 'Ui',
  INFRASTRUCTURE = 'INFRASTRUCTURE',
  APPLICATION = 'APPLICATION',
  DOMAIN = 'DOMAIN',
}

export default LoggerLayer;
