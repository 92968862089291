import { OrderReturn, ReturnedReference } from 'core/domain/model/orderReturn/write/OrderReturn';
import FinishOrderReturnFailedError from 'core/domain/model/orderReturn/write/FinishOrderReturnFailedError';
import OrderReturnWriter from 'core/domain/model/orderReturn/write/OrderReturnWriter';
import HttpClient from 'shared/domain/delivery/HttpClient';
class HttpOrderReturnWriter implements OrderReturnWriter {
  private readonly httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }
  public finishOrderReturn(orderReturn: OrderReturn, references: ReturnedReference[]): Promise<void> {
    const body = {
      references,
    };
    return this.httpClient.patch<void>(`/v1/orders/${orderReturn.id}/finish`, body).catch(() => {
      throw new FinishOrderReturnFailedError();
    });
  }
}

export default HttpOrderReturnWriter;
