import { Command } from '@lookiero/messaging.js';

class RemoveNotification extends Command {
  readonly notificationId: string;

  constructor(notificationId: string) {
    super();
    this.notificationId = notificationId;
  }

  /* istanbul ignore next */
  protected messageName(): string {
    return 'RemoveNotification';
  }
}

export default RemoveNotification;
