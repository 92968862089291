import AggregateRoot from 'shared/domain/AggregateRoot';
import EmployeeLoggedIn from './event/EmployeeLoggedIn';
import EmployeeLoggedOut from './event/EmployeeLoggedOut';
import { JwtToken } from '../JwtToken';

class Employee extends AggregateRoot {
  public userId?: string;
  public authToken?: JwtToken;
  public username?: string;
  public roles?: string[];

  public constructor({
    userId,
    authToken,
    username,
    roles,
  }: {
    userId?: string;
    authToken?: string;
    username?: string;
    roles?: string[];
  }) {
    super();
    this.userId = userId;
    this.authToken = authToken;
    this.username = username;
    this.roles = roles;
  }

  public static create(employeeParam: {
    userId: string;
    authToken: string;
    username: string;
    roles: string[];
  }): Employee {
    const employee = new Employee({ ...employeeParam });
    employee.record(new EmployeeLoggedIn());
    return employee;
  }

  public static logout(): Employee {
    const employee = new Employee({});
    employee.record(new EmployeeLoggedOut());
    return employee;
  }
}

export default Employee;
