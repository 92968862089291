import { Query } from '@lookiero/messaging.js';

class FindOrderReturn extends Query {
  public readonly courierOrderId?: string;
  public readonly publicOrderId?: string;

  public constructor({ courierOrderId, publicOrderId }: { courierOrderId?: string; publicOrderId?: string }) {
    super();
    this.courierOrderId = courierOrderId;
    this.publicOrderId = publicOrderId;
  }

  /* istanbul ignore next */
  protected messageName(): string {
    return 'FindOrderReturn';
  }
}

export default FindOrderReturn;
