import HttpClient, { HttpClientResponseError, HttpErrorCodes } from 'shared/domain/delivery/HttpClient';
import PalletReader from 'core/domain/model/pallet/read/PalletReader';
import { PalletMinimalView, PalletView } from 'core/domain/model/pallet/read/PalletView';
import { PalletViewStatus } from '../../../domain/model/pallet/read/PalletViewStatus';

class HttpPalletReader implements PalletReader {
  private readonly httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async findPallet(palletNumber: string): Promise<PalletView | undefined> {
    return this.httpClient.get<PalletView>(`/v2/pallets?labelNumber=${palletNumber}`).catch(this.catchPalletError);
  }

  private catchPalletError(error: HttpClientResponseError) {
    if (error.status === HttpErrorCodes.NOT_FOUND) {
      return Promise.resolve(undefined);
    }

    throw error;
  }

  findFinishedPallets(): Promise<PalletMinimalView[]> {
    return this.httpClient.get<PalletMinimalView[]>(`/v1/pallets?status=${PalletViewStatus.FINISHED}`);
  }

  findDraftPallets(): Promise<PalletMinimalView[]> {
    return this.httpClient.get<PalletMinimalView[]>(`/v1/pallets?status=${PalletViewStatus.DRAFT}`);
  }
}
export default HttpPalletReader;
