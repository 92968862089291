export interface HttpOrderItemView {
  readonly referenceId: string;
  readonly barcode: string;
  readonly reference: string;
  readonly brand: string;
  readonly title: string;
  readonly colour: {
    code: string;
  };
  readonly market: {
    size: string;
  };
  readonly image: string;
}

export interface HttpQuantityView {
  readonly quantity: {
    real: number;
  };
}

export interface OrderItemView {
  readonly id: string;
  readonly barcode: string;
  readonly reference: string;
  readonly brand: string;
  readonly title: string;
  readonly colour: string;
  readonly size: string;
  readonly image: string;
}

export type RouteItemStock = number;

export const orderItemFromHttpDto = (response: HttpOrderItemView): OrderItemView => ({
  id: response.referenceId,
  barcode: response.barcode,
  reference: response.reference,
  brand: response.brand,
  title: response.title,
  colour: response.colour.code,
  size: response.market.size,
  image: response.image,
});

export const stockFromDto = (response: HttpQuantityView): RouteItemStock => response.quantity.real;
