import { Query } from '@lookiero/messaging.js';

class GetCurrentEmployee extends Query {
  /* istanbul ignore next */
  protected messageName(): string {
    return 'GetCurrentEmployee';
  }
}

export default GetCurrentEmployee;
