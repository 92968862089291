import PrinterWriter from 'core/domain/model/printer/write/PrinterWriter';
import Printer from 'core/domain/model/printer/write/Printer';
import { CommandHandler } from '@lookiero/messaging.js';
import AssignPreparerPrinter from './AssignPreparerPrinter';

class AssignPreparerPrinterHandler implements CommandHandler<AssignPreparerPrinter> {
  private readonly printerWriter: PrinterWriter;

  public constructor(printerWriter: PrinterWriter) {
    this.printerWriter = printerWriter;
  }

  public async execute(command: AssignPreparerPrinter): Promise<void> {
    const route = Printer.assignPreparerPrinter(command.printerLine);
    await this.printerWriter.savePreparerPrinter(route);
  }
}

export default AssignPreparerPrinterHandler;
