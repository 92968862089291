import { QueryHandler } from '@lookiero/messaging.js';
import FindDraftPallets from './FindDraftPallets';
import { PalletMinimalView } from 'core/domain/model/pallet/read/PalletView';
import PalletReader from 'core/domain/model/pallet/read/PalletReader';

class FindDraftPalletsHandler implements QueryHandler<FindDraftPallets> {
  private readonly repository: PalletReader;

  public constructor(repository: PalletReader) {
    this.repository = repository;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async handle(query: FindDraftPallets): Promise<PalletMinimalView[]> {
    return this.repository.findDraftPallets();
  }
}

export default FindDraftPalletsHandler;
