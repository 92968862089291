import { DomainEvent } from '@lookiero/messaging.js';
import Pallet from '../../Pallet';

class PalletSendFinished extends DomainEvent {
  readonly pallet: Partial<Pallet>;

  public constructor(pallet: Pallet) {
    super();
    this.pallet = { ...pallet };
  }

  /* istanbul ignore next */
  public messageName(): string {
    return 'PalletSendFinished';
  }
}

export default PalletSendFinished;
