export enum RouteDocumentType {
  COLLAGE = 'COLLAGE',
  ORDER_SUMMARY = 'ORDER_SUMMARY',
  NOTE = 'NOTE',
}

class RouteDocument {
  readonly document: RouteDocumentType;

  private constructor(routeDocumentType: RouteDocumentType) {
    this.document = routeDocumentType;
  }

  static createRouteDocument(routeDocumentType: RouteDocumentType): RouteDocument {
    return new RouteDocument(routeDocumentType);
  }

  static createRouteDocuments(routeDocumentTypes: RouteDocumentType[]): RouteDocument[] {
    return routeDocumentTypes.map((routeDocumentType) => RouteDocument.createRouteDocument(routeDocumentType));
  }

  static createAllRouteDocuments(): RouteDocument[] {
    return RouteDocument.createRouteDocuments([
      RouteDocumentType.COLLAGE,
      RouteDocumentType.ORDER_SUMMARY,
      RouteDocumentType.NOTE,
    ]);
  }

  static createRouteDocumentsFromStringValues(stringValues: string[]): RouteDocument[] {
    return (
      stringValues
        .map((stringValue) => RouteDocument.getRouteDocumentsTypeFromStringValue(stringValue))
        .filter((routeDocumentType) => routeDocumentType !== undefined)
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .map((routeDocumentType) => RouteDocument.createRouteDocument(routeDocumentType!))
    );
  }

  static getAvailableDocumentTypes(): RouteDocumentType[] {
    return this.createAllRouteDocuments().map((routeDocument) => routeDocument.document);
  }

  private static getRouteDocumentsTypeFromStringValue(stringValue: string): RouteDocumentType | undefined {
    return Object.values(RouteDocumentType).find((value) => value === stringValue);
  }
}

export default RouteDocument;
