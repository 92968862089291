import DomainError from 'shared/domain/error/DomainError';
import ErrorCode from 'shared/domain/error/ErrorCode';
import { I18N_MESSAGES } from 'shared/i18n/route';

class RouteInvalidError extends DomainError {
  readonly routeIds: string[];

  public constructor(routeIds?: string[]) {
    super(ErrorCode.ROUTE_INVALID, I18N_MESSAGES.ROUTE_LIST_REMOVED_INVALID_ERROR, RouteInvalidError.name);
    this.routeIds = routeIds ? routeIds : [];
  }
}

export default RouteInvalidError;
