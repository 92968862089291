import { QueryHandler } from '@lookiero/messaging.js';
import GetRouteItem from './GetRouteItem';
import RouteItemReader from '../../../domain/model/routeItem/read/RouteItemReader';
import RouteItemView from '../../../domain/model/routeItem/read/RouteItemView';

class GetRouteItemHandler implements QueryHandler<GetRouteItem> {
  private readonly reader: RouteItemReader;

  constructor(reader: RouteItemReader) {
    this.reader = reader;
  }

  public handle(query: GetRouteItem): Promise<RouteItemView> {
    return this.reader.getRouteItem({ ...query });
  }
}

export default GetRouteItemHandler;
