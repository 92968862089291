import NotificationRepository from 'core/domain/model/notification/NotificationRepository';
import { NotificationProjection } from 'core/domain/model/notification/NotificationProjection';
import { QueryHandler } from '@lookiero/messaging.js';
import ListNotifications from './ListNotifications';

class ListNotificationsHandler implements QueryHandler<ListNotifications> {
  private readonly repository: NotificationRepository;

  public constructor(repository: NotificationRepository) {
    this.repository = repository;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public handle(query: ListNotifications): Promise<NotificationProjection[]> {
    return this.repository.list();
  }
}

export default ListNotificationsHandler;
