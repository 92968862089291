import { QueryHandler } from '@lookiero/messaging.js';
import CourierRepository from 'core/domain/model/courier/CourierRepository';
import FindCouriers from './FindCouriers';
import CourierView from 'core/domain/model/courier/CourierView';

class FindCouriersHandler implements QueryHandler<FindCouriers> {
  private readonly repository: CourierRepository;

  public constructor(repository: CourierRepository) {
    this.repository = repository;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async handle(query: FindCouriers): Promise<CourierView[]> {
    return this.repository.list();
  }
}

export default FindCouriersHandler;
