import Printer from 'core/domain/model/printer/write/Printer';
import PrinterWriter from 'core/domain/model/printer/write/PrinterWriter';

class StoragePrinterWriter implements PrinterWriter {
  private static readonly PREPARER_PRINTER_KEY = 'preparer_printer';
  private static readonly RETURNS_PRINTER_KEY = 'returns_printer';

  private readonly storage: Storage;

  constructor(storage: Storage) {
    this.storage = storage;
  }

  savePreparerPrinter(printer: Printer): Promise<void> {
    this.storage.save(StoragePrinterWriter.PREPARER_PRINTER_KEY, printer.line);
    return Promise.resolve();
  }

  saveReturnsPrinter(printer: Printer): Promise<void> {
    this.storage.save(StoragePrinterWriter.RETURNS_PRINTER_KEY, printer.line);
    return Promise.resolve();
  }
}

export default StoragePrinterWriter;
