import { Command } from '@lookiero/messaging.js';

class PrintOrderReturnReference extends Command {
  readonly referenceId: string;
  readonly line: string;

  constructor(referenceId: string, line: string) {
    super();
    this.referenceId = referenceId;
    this.line = line;
  }

  /* istanbul ignore next */
  protected messageName(): string {
    return 'PrintOrderReturnReference';
  }
}

export default PrintOrderReturnReference;
