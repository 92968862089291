import RouteView from 'core/domain/model/route/read/RouteView';
import { QueryHandler } from '@lookiero/messaging.js';
import RouteReader from 'core/domain/model/route/read/RouteReader';
import FindRouteWithPreparedTrolley from './FindRouteWithPreparedTrolley';

class FindRouteWithPreparedTrolleyHandler implements QueryHandler<FindRouteWithPreparedTrolley> {
  private readonly reader: RouteReader;

  public constructor(reader: RouteReader) {
    this.reader = reader;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async handle(query: FindRouteWithPreparedTrolley): Promise<RouteView> {
    return this.reader.findPreparedTrolley(query.trolleyId);
  }
}

export default FindRouteWithPreparedTrolleyHandler;
