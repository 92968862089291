import React, { FC, useCallback, useState } from 'react';
import NotificationsView from '../notification/NotificationsView';
import SideMenu from './SideMenu/SideMenu';
import Header from './Header/Header';
import './app.css';

interface AppProps {
  children: JSX.Element;
}

const App: FC<AppProps> = ({ children }) => {
  const [showSideMenu, setShowSideMenu] = useState(false);
  const handleShowSideMenu = useCallback(() => setShowSideMenu(!showSideMenu), [showSideMenu]);

  return (
    <>
      <Header onShowSideMenu={handleShowSideMenu} />
      <div className="app__layout">
        {showSideMenu ? <SideMenu onShowSideMenu={handleShowSideMenu} /> : null}
        <div className="app__views">
          {children}
          <NotificationsView />
        </div>
      </div>
    </>
  );
};

export default App;
