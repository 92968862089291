import AggregateRoot from 'shared/domain/AggregateRoot';
import RoutesRemoved from './event/routesRemoved/RoutesRemoved';
import Route from './Route';

class RouteList extends AggregateRoot {
  readonly routes: Route[];

  private constructor(routes: Route[]) {
    super();
    this.routes = routes;
  }

  public static createRouteList(routes: Route[]): RouteList {
    return new RouteList(routes);
  }

  public static removeRoutes(routes: Route[]): RouteList {
    const routeList = RouteList.createRouteList(routes);
    routeList.record(new RoutesRemoved(routes));
    return routeList;
  }
}

export default RouteList;
