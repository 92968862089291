import AggregateRoot from 'shared/domain/AggregateRoot';
import PrinterAssigned from './event/printerAssigned/PrinterAssigned';

class Printer extends AggregateRoot {
  readonly line: string;

  public constructor(line: string) {
    super();
    this.line = line;
  }

  static assignPreparerPrinter(line: string): Printer {
    return new Printer(line);
  }

  static assignReturnsPrinter(line: string): Printer {
    const printer = new Printer(line);
    printer.record(new PrinterAssigned(printer));
    return printer;
  }
}

export default Printer;
