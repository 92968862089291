import RouteView from 'core/domain/model/route/read/RouteView';
import { QueryHandler } from '@lookiero/messaging.js';
import RouteReader from 'core/domain/model/route/read/RouteReader';
import FindRoutes from './FindRoutes';

class FindRoutesHandler implements QueryHandler<FindRoutes> {
  private readonly reader: RouteReader;

  public constructor(reader: RouteReader) {
    this.reader = reader;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async handle(query: FindRoutes): Promise<RouteView[]> {
    return this.reader.findRoutes(query.pickerId, query.statusFilter, query.routeTypes);
  }
}

export default FindRoutesHandler;
