import { Command } from '@lookiero/messaging.js';

class RemoveRoutes extends Command {
  public readonly routeIds: string[];

  public constructor(routeIds: string[]) {
    super();
    this.routeIds = routeIds;
  }

  /* istanbul ignore next */
  protected messageName(): string {
    return 'RemoveRoutes';
  }
}

export default RemoveRoutes;
