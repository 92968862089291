import { Command } from '@lookiero/messaging.js';

class SendFinishedPallet extends Command {
  public palletId: string;

  public constructor(palletId: string) {
    super();
    this.palletId = palletId;
  }

  /* istanbul ignore next */
  protected messageName(): string {
    return 'SendFinishedPallet';
  }
}

export default SendFinishedPallet;
