import React, { ReactNode, Component, ComponentType } from 'react';
import Logger from 'core/domain/model/logger/Logger';
import LoggerError from 'core/domain/model/logger/LoggerError';
import LoggerLayer from 'core/domain/model/logger/LoggerLayer';

interface FallbackComponentProps {
  readonly onReport: () => void;
}

interface ErrorBoundaryState {
  readonly hasError: boolean;
  readonly eventId: string;
}

interface ErrorBoundaryProps {
  readonly logger: Logger;
  readonly fallback: ComponentType<FallbackComponentProps>;
  readonly children: ReactNode;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  private readonly logger: Logger;

  public constructor(props: ErrorBoundaryProps) {
    super(props);

    this.logger = props.logger;
    this.state = {
      hasError: false,
      eventId: '',
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public componentDidCatch(error: any, errorInfo: any): void {
    const eventId = this.logger.error(new LoggerError({ layer: LoggerLayer.UI, error: error, info: errorInfo }));
    this.setState({ eventId, hasError: true });
  }

  public render(): ReactNode {
    const { fallback: Fallback } = this.props;

    return this.state.hasError ? (
      <Fallback onReport={(): void => this.logger.report(this.state.eventId)} />
    ) : (
      this.props.children
    );
  }
}

export default ErrorBoundary;
