import { Command } from '@lookiero/messaging.js';

class RemoveOrders extends Command {
  public readonly palletId: string;
  public readonly publicOrderIds: string[];

  public constructor(palletIdParam: string, publicOrderIdsParam: string[]) {
    super();
    this.palletId = palletIdParam;
    this.publicOrderIds = [...publicOrderIdsParam];
  }

  /* istanbul ignore next */
  protected messageName(): string {
    return 'RemoveOrders';
  }
}

export default RemoveOrders;
