import { DomainEvent } from '@lookiero/messaging.js';

class ErrorThrown extends DomainEvent {
  readonly error: Error;

  constructor(error: Error) {
    super();
    this.error = error;
  }

  public messageName(): string {
    return 'ErrorThrown';
  }
}

export default ErrorThrown;
