import { v4 as uuid } from 'uuid';
import { CommandBus, ProcessManager } from '@lookiero/messaging.js';
import CreateNotification from 'core/application/notification/createNotification/CreateNotification';
import NotificationLevel from 'core/domain/model/notification/NotificationLevel';
import OrdersRemoved from './OrdersRemoved';
import { I18N_MESSAGES, PREFIX } from 'shared/i18n/shipping';

class OrdersRemovedListener implements ProcessManager<OrdersRemoved> {
  commandBus!: CommandBus;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async process(event: OrdersRemoved): Promise<void> {
    const notificationCommand = new CreateNotification(
      uuid(),
      NotificationLevel.SUCCESS,
      PREFIX + I18N_MESSAGES.NOTIFICATION_ORDERS_REMOVED,
    );
    this.commandBus.dispatch(notificationCommand);
  }
}

export default OrdersRemovedListener;
