import RouteInvalidError from 'core/domain/model/routeList/write/RouteInvalidError';
import RouteList from 'core/domain/model/routeList/write/RouteList';
import RouteListWriter from 'core/domain/model/routeList/write/RouteListWriter';
import HttpClient, { HttpClientResponseError, HttpErrorCodes } from 'shared/domain/delivery/HttpClient';
import DomainError from 'shared/domain/error/DomainError';

class HttpRouteListWriter implements RouteListWriter {
  private readonly httpClient: HttpClient;

  private static readonly ROUTE_DOMAIN_ERRORS: Record<number, (ids?: string[]) => DomainError> = {
    [HttpErrorCodes.CONFLICT]: (routeIds?: string[]) => new RouteInvalidError(routeIds),
  };

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public removeRoutes(routeList: RouteList): Promise<void> {
    const routes = routeList.routes.map((routeItem) => {
      return { id: routeItem.id };
    });
    const body = {
      routes,
    };

    return this.httpClient.put<void>('/v2/routes', body).catch(this.catchRouteConflict);
  }

  private catchRouteConflict(error: HttpClientResponseError): void {
    let invalidRoutes: string[] | undefined = undefined;
    if (error.body) {
      invalidRoutes = error.body.unremovedRouteIds as string[] | undefined;
    }

    const domainErrorFn = error.status ? HttpRouteListWriter.ROUTE_DOMAIN_ERRORS[error.status] : undefined;
    throw domainErrorFn ? domainErrorFn(invalidRoutes) : error;
  }
}

export default HttpRouteListWriter;
