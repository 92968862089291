import EmployeeReader from 'core/domain/model/employee/read/EmployeeReader';
import EmployeeView from 'core/domain/model/employee/read/EmployeeView';
import Storage from 'shared/domain/storage/Storage';
import LocalStorageEmployeeType from '../LocalStorageEmployeeType';

class StorageEmployeeReader implements EmployeeReader {
  private static readonly EMPLOYEE_KEY = 'employee';

  private readonly storage: Storage;

  constructor(storage: Storage) {
    this.storage = storage;
  }

  public getCurrentEmployeeView(): EmployeeView | undefined {
    const employee = this.storage.get(StorageEmployeeReader.EMPLOYEE_KEY);
    const decodedEmployee = employee ? this.decode(employee) : undefined;
    return decodedEmployee ? new EmployeeView({ ...decodedEmployee }) : undefined;
  }

  public isStillLoggedIn(): boolean {
    return this.storage.has(StorageEmployeeReader.EMPLOYEE_KEY);
  }

  private decode(value: string): LocalStorageEmployeeType | undefined {
    if (!value) return undefined;

    return JSON.parse(value);
  }
}

export default StorageEmployeeReader;
