import InventoryReader from 'core/domain/model/inventory/read/InventoryReader';
import InventoryView from 'core/domain/model/inventory/read/InventoryView';
import InventoryLocationInvalidError from 'core/domain/model/inventory/InventoryLocationInvalidError';
import HttpClient, { HttpClientResponseError, HttpErrorCodes } from 'shared/domain/delivery/HttpClient';
import { HttpInventoryView } from './HttpInventoryView';

class HttpInventoryReader implements InventoryReader {
  private readonly httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public findInventory(location: string): Promise<InventoryView> {
    return this.httpClient
      .get<HttpInventoryView>(`/v2/inventory/locations/${location}`)
      .then((response) => new InventoryView({ ...response }))
      .catch((error: HttpClientResponseError) => {
        if (error.status === HttpErrorCodes.BAD_REQUEST) {
          throw new InventoryLocationInvalidError();
        } else {
          throw error;
        }
      });
  }
}

export default HttpInventoryReader;
