import HttpClient from 'shared/domain/delivery/HttpClient';
import Environment from 'shared/domain/environment/Environment';
import EnvironmentFetcher from 'shared/domain/environment/EnvironmentFetcher';
import packageInfo from '../../../../package.json';

class HttpEnvironmentFetcher implements EnvironmentFetcher {
  private readonly client: HttpClient;

  public constructor(client: HttpClient) {
    this.client = client;
  }

  public fetch(): Promise<Environment> {
    return this.client.get<Environment>(`/config.json?${packageInfo.version}`);
  }
}

export default HttpEnvironmentFetcher;
