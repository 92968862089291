import { Command, CommandHandler, DomainEvent, ProcessManager, Query, QueryHandler } from '@lookiero/messaging.js';
import { Container } from 'inversify';
import { fromStringToSymbol } from '../_data-transfrom/fromStringToSymbol';

interface HandlerContainerFunctionArgs {
  readonly container: Container;
}

type Handlers = QueryHandler<Query> | CommandHandler<Command> | ProcessManager<DomainEvent>;

type HandlerContainerFunctionResult = (handlerName: string) => Handlers;

interface HandlerContainerFunction {
  (args: HandlerContainerFunctionArgs): HandlerContainerFunctionResult;
}

const handlerContainer: HandlerContainerFunction =
  ({ container }) =>
  (handlerName) =>
    container.get(fromStringToSymbol(handlerName));

export { handlerContainer };
