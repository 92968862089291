import { DomainEvent, useAsk, useDispatch } from '@lookiero/messaging.js';
import CheckCurrentEmployeeIsLoggedIn from 'core/application/employee/checkCurrentEmployeeIsLoggedIn/CheckCurrentEmployeeIsLoggedIn';
import EmployeeLoggedIn from 'core/domain/model/employee/write/event/EmployeeLoggedIn';
import EmployeeLoggedOut from 'core/domain/model/employee/write/event/EmployeeLoggedOut';
import ThrowError from 'shared/application/error/throwError/ThrowError';

const useIsLoggedEmployee = (): boolean | undefined => {
  const dispatch = useDispatch();
  const { data } = useAsk<boolean, Error>({
    query: new CheckCurrentEmployeeIsLoggedIn(),
    invalidation: (event: DomainEvent) => event instanceof EmployeeLoggedIn || event instanceof EmployeeLoggedOut,
    options: { onError: (error: Error) => dispatch(new ThrowError(error)) },
  });

  return data;
};

export default useIsLoggedEmployee;
