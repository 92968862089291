import NotificationRepository from 'core/domain/model/notification/NotificationRepository';
import Notification from 'core/domain/model/notification/Notification';
import { NotificationProjection } from 'core/domain/model/notification/NotificationProjection';

class InMemoryNotificationRepository implements NotificationRepository {
  private notifications: Record<string, NotificationProjection>;

  public constructor() {
    this.notifications = {};
  }

  public list(): Promise<NotificationProjection[]> {
    const notifications = Object.values(this.notifications);

    return Promise.resolve(notifications);
  }

  public ofId(id: string): Promise<NotificationProjection> {
    const notification = this.notifications[id];

    if (!notification) {
      return Promise.reject(new Error(`Notification for the provided id ${id} does not exist`));
    }

    return Promise.resolve(notification);
  }

  public save(notification: Notification): Promise<void> {
    const notificationCopy: NotificationProjection = { ...notification };
    this.notifications[notification.id] = notificationCopy;

    return Promise.resolve();
  }

  public remove(id: string): Promise<void> {
    return this.ofId(id)
      .then(() => {
        delete this.notifications[id];
      })
      .catch(() => console.info(`Cannot find notification with id ${id} to remove it`));
  }
}

export default InMemoryNotificationRepository;
