class Route {
  readonly id: string;

  private constructor(idParam: string) {
    this.id = idParam;
  }

  public static createRoute(idParam: string): Route {
    return new Route(idParam);
  }
}

export default Route;
