import { DomainEvent, useAsk, useDispatch } from '@lookiero/messaging.js';
import { NotificationProjection } from 'core/domain/model/notification/NotificationProjection';
import NotificationCreated from 'core/domain/model/notification/event/NotificationCreated';
import NotificationRemoved from 'core/domain/model/notification/event/NotificationRemoved';
import ListNotifications from 'core/application/notification/listNotifications/ListNotifications';
import ThrowError from 'shared/application/error/throwError/ThrowError';

const useListNotifications = (): NotificationProjection[] => {
  const dispatch = useDispatch();
  const { data: notifications = [] } = useAsk<NotificationProjection[], Error>({
    query: new ListNotifications(),
    invalidation: (event: DomainEvent) => event instanceof NotificationCreated || event instanceof NotificationRemoved,
    options: { staleTime: Infinity, onError: (error: Error) => dispatch(new ThrowError(error)) },
  });

  return notifications;
};

export default useListNotifications;
