import { CommandHandler, EventBus } from '@lookiero/messaging.js';
import RouteWriter from 'core/domain/model/route/write/RouteWriter';
import Route from 'core/domain/model/route/write/Route';
import AssignManualRoute from './AssignManualRoute';

class AssignManualRouteHandler implements CommandHandler<AssignManualRoute> {
  private readonly routeWriter: RouteWriter;
  eventBus!: EventBus;

  constructor(routeWriter: RouteWriter) {
    this.routeWriter = routeWriter;
  }

  async execute(command: AssignManualRoute): Promise<void> {
    const route = Route.assignManualRoute(command.orderId);
    await this.routeWriter.assignManualRoute(route);
    this.eventBus.publish([...route.recordedEvents()]);
  }
}

export default AssignManualRouteHandler;
