import { CommandHandler, EventBus } from '@lookiero/messaging.js';
import OrderReturnReferenceWriter from 'core/domain/model/printOrderReturnReference/write/OrderReturnReferenceWriter';
import { OrderReturnReference } from 'core/domain/model/printOrderReturnReference/write/OrderReturnReference';
import PrintOrderReturnReference from './PrintOrderReturnReference';
import PrintLine from 'shared/domain/model/PrintLine';

class PrintOrderReturnReferenceHandler implements CommandHandler<PrintOrderReturnReference> {
  private readonly orderReturnReferenceWriter: OrderReturnReferenceWriter;
  eventBus!: EventBus;

  constructor(orderReturnReferenceWriter: OrderReturnReferenceWriter) {
    this.orderReturnReferenceWriter = orderReturnReferenceWriter;
  }

  async execute(command: PrintOrderReturnReference): Promise<void> {
    const printLine = PrintLine.createPrintLine(command.line);
    const reference = OrderReturnReference.printOrderReturnReference(command.referenceId);

    await this.orderReturnReferenceWriter.printOrderReturnReferenceLabel(reference, printLine);

    this.eventBus.publish([...reference.recordedEvents()]);
  }
}

export default PrintOrderReturnReferenceHandler;
