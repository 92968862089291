import { DomainEvent } from '@lookiero/messaging.js';

class EmployeeLoggedIn extends DomainEvent {
  public constructor() {
    super();
  }

  /* istanbul ignore next */
  public messageName(): string {
    return 'EmployeeLoggedIn';
  }
}

export default EmployeeLoggedIn;
