import { Query } from '@lookiero/messaging.js';

class FindDraftPallets extends Query {
  /* istanbul ignore next */
  protected messageName(): string {
    return 'FindDraftPallets';
  }
}

export default FindDraftPallets;
