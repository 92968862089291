import LoggerLayer from './LoggerLayer';

/* istanbul ignore file */
abstract class LoggerEvent {
  public readonly layer: LoggerLayer;

  public constructor(layer: LoggerLayer) {
    this.layer = layer;
  }
}

export default LoggerEvent;
