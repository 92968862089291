import { BarcodeEan13 } from 'shared/domain/model/BarcodeEan13';
import { OrderReturnViewStatus } from './OrderReturnViewStatus';
import { ReferenceReturnView } from './ReferenceReturnView';

export class OrderReturnView {
  readonly id: string;
  readonly country: string;
  readonly courierOrderId: CourierOrderIdView;
  readonly publicOrderId: string;
  readonly status: OrderReturnViewStatus;
  readonly references: ReferenceReturnView[];

  constructor({
    id,
    country,
    courierOrderId,
    publicOrderId,
    status,
    references,
  }: {
    id: string;
    country: string;
    courierOrderId: CourierOrderIdView;
    publicOrderId: string;
    status: OrderReturnViewStatus;
    references: ReferenceReturnView[];
  }) {
    this.id = id;
    this.country = country;
    this.courierOrderId = courierOrderId;
    this.publicOrderId = publicOrderId;
    this.status = status;
    this.references = references;
  }

  getNumberOfReferencesReturned = (): number => this.references.filter((reference) => reference.returned).length;
  isOrderReturnFinished = (): boolean => this.status === 'FINISHED';
}

export type CourierOrderIdView = BarcodeEan13;
