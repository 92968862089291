class PrintLine {
  readonly line: string;

  private constructor(lineParam: string) {
    this.line = lineParam;
  }

  static createPrintLine(lineParam: string): PrintLine {
    return new PrintLine(lineParam);
  }

  lineToNumber(): number {
    return Number(this.line);
  }
}

export default PrintLine;
