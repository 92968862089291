import { CommandHandler, EventBus } from '@lookiero/messaging.js';
import RouteWriter from 'core/domain/model/route/write/RouteWriter';
import Route from 'core/domain/model/route/write/Route';
import RouteDocument from 'core/domain/model/route/write/RouteDocument';
import PrintRouteDocuments from './PrintRouteDocuments';
import PrintLine from 'shared/domain/model/PrintLine';

class PrintRouteDocumentsHandler implements CommandHandler<PrintRouteDocuments> {
  private readonly routeWriter: RouteWriter;
  eventBus!: EventBus;

  constructor(routeWriter: RouteWriter) {
    this.routeWriter = routeWriter;
  }

  async execute(command: PrintRouteDocuments): Promise<void> {
    const printLine = PrintLine.createPrintLine(command.line);
    const allDocuments = command.documents
      ? RouteDocument.createRouteDocumentsFromStringValues(command.documents)
      : RouteDocument.createAllRouteDocuments();
    const route = Route.printRouteDocuments(command.routeId);

    await this.routeWriter.printDocumentsInRoute(route, printLine, allDocuments);

    this.eventBus.publish([...route.recordedEvents()]);
  }
}

export default PrintRouteDocumentsHandler;
