import HttpClient from 'shared/domain/delivery/HttpClient';

class HttpPrinterReader {
  private readonly httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async findPrinters(): Promise<string[]> {
    return this.httpClient.get<string[]>('/printers/v1/lines');
  }
}

export default HttpPrinterReader;
