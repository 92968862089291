import DomainError from 'shared/domain/error/DomainError';
import ErrorCode from 'shared/domain/error/ErrorCode';
import { I18N_MESSAGES } from 'shared/i18n/picking';

class RouteViewNotFoundError extends DomainError {
  public constructor() {
    super(ErrorCode.ROUTE_VIEW_NOT_FOUND, I18N_MESSAGES.ROUTE_VIEW_NOT_FOUND_ERROR, RouteViewNotFoundError.name);
  }
}

export default RouteViewNotFoundError;
