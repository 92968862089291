import { DomainEvent } from '@lookiero/messaging.js';
import { JwtToken } from '../../../JwtToken';

class EmployeeAuthenticated extends DomainEvent {
  public userId: string;
  public authToken: JwtToken;
  public username: string;
  public roles: string[];

  public constructor({
    userId,
    authToken,
    username,
    roles,
  }: {
    userId: string;
    authToken: string;
    username: string;
    roles: string[];
  }) {
    super();
    this.userId = userId;
    this.username = username;
    this.authToken = authToken;
    this.roles = roles;
  }
  /* istanbul ignore next */
  public messageName(): string {
    return 'EmployeeAuthenticated';
  }
}

export default EmployeeAuthenticated;
