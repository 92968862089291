import { DomainEvent } from '@lookiero/messaging.js';

abstract class AggregateRoot {
  private domainEvents: DomainEvent[];

  public constructor() {
    this.domainEvents = [];
  }

  protected record(event: DomainEvent): void {
    this.domainEvents.push(event);
  }

  public recordedEvents(): DomainEvent[] {
    const events = this.domainEvents;
    this.domainEvents = [];

    return events;
  }
}

export default AggregateRoot;
