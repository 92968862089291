import CreateEmployeeHandler from 'core/application/employee/create/CreateEmployeeHandler';
import LoginEmployeeHandler from 'core/application/employee/login/LoginEmployeeHandler';
import LogoutEmployeeHandler from 'core/application/employee/logout/LogoutEmployeeHandler';
import FinalizeInventoryHandler from 'core/application/inventory/finalizeInventory/FinalizeInventoryHandler';
import InventoryReferenceHandler from 'core/application/inventory/inventoryReference/InventoryReferenceHandler';
import RemoveInventoryHandler from 'core/application/inventory/removeInventory/RemoveInventoryHandler';
import CreateNotificationHandler from 'core/application/notification/createNotification/CreateNotificationHandler';
import RemoveNotificationHandler from 'core/application/notification/removeNotification/RemoveNotificationHandler';
import FinishOrderReturnHandler from 'core/application/orderReturn/finishOrderReturn/FinishOrderReturnHandler';
import PrintOrderReturnReferenceHandler from 'core/application/orderReturnReference/printOrderReturnReference/PrintOrderReturnReferenceHandler';
import AddOrderHandler from 'core/application/pallet/addOrder/AddOrderHandler';
import PrintLabelHandler from 'core/application/pallet/printPallet/PrintLabelHandler';
import RemoveOrdersHandler from 'core/application/pallet/removeOrders/RemoveOrdersHandler';
import SendFinishedPalletHandler from 'core/application/pallet/sendFinishedPallet/SendFinishedPalletHandler';
import SendPalletsHandler from 'core/application/pallet/sendPallet/SendPalletsHandler';
import AssignPreparerPrinterHandler from 'core/application/printer/assignPreparerPrinter/AssignPreparerPrinterHandler';
import AssignReturnsPrinterHandler from 'core/application/printer/assignReturnsPrinter/AssignReturnsPrinterHandler';
import AssignManualRouteHandler from 'core/application/route/assignManualRoute/AssignManualRouteHandler';
import AssignPreparerHandler from 'core/application/route/assignPreparer/AssignPreparerHandler';
import AssignRouteToTrolleyHandler from 'core/application/route/assignRouteToTrolley/AssignRouteToTrolleyHandler';
import AssignRouteTrolleyToPickerHandler from 'core/application/route/assignRouteTrolleyToPicker/AssignRouteTrolleyToPickerHandler';
import PrintRouteDocumentsHandler from 'core/application/route/printRouteDocuments/PrintRouteDocumentsHandler';
import RemoveRoutesHandler from 'core/application/routeList/removeRoutes/RemoveRoutesHandler';
import HttpCourierRepository from 'core/infrastructure/persistence/courier/HttpCourierRepository';
import StorageEmployeeWriter from 'core/infrastructure/persistence/employee/write/StorageEmployeeWriter';
import HttpInventoryWriter from 'core/infrastructure/persistence/inventory/HttpInventoryWriter';
import HttpOrderReturnWriter from 'core/infrastructure/persistence/orderReturn/HttpOrderReturnWriter';
import HttpOrderReturnReferenceWriter from 'core/infrastructure/persistence/orderReturnReference/HttpOrderReturnReferenceWriter';
import HttpPalletWriter from 'core/infrastructure/persistence/pallet/HttpPalletWriter';
import StoragePrinterWriter from 'core/infrastructure/persistence/printer/write/StoragePrinterWriter';
import HttpRouteWriter from 'core/infrastructure/persistence/route/HttpRouteWriter';
import HttpRouteListWriter from 'core/infrastructure/persistence/routeList/HttpRouteListWriter';

const commandRegisterList = [
  {
    identifier: 'HttpRouteWriter',
    dependencies: ['PickingHttpClient'],
    classInjection: HttpRouteWriter,
  },
  {
    identifier: 'EmployeeWriter',
    dependencies: ['LocalStorage'],
    classInjection: StorageEmployeeWriter,
  },
  {
    identifier: 'HttpRouteListWriter',
    dependencies: ['PickingHttpClient'],
    classInjection: HttpRouteListWriter,
  },
  {
    identifier: 'HttpPalletWriter',
    dependencies: ['ShippingHttpClient'],
    classInjection: HttpPalletWriter,
  },
  {
    identifier: 'HttpOrderReturnWriter',
    dependencies: ['ReturnHttpClient'],
    classInjection: HttpOrderReturnWriter,
  },
  {
    identifier: 'HttpInventoryWriter',
    dependencies: ['WarehouseHttpClient'],
    classInjection: HttpInventoryWriter,
  },
  {
    identifier: 'PrinterWriter',
    dependencies: ['LocalStorage'],
    classInjection: StoragePrinterWriter,
  },
  {
    identifier: 'CourierRepository',
    dependencies: ['ShippingHttpClient'],
    classInjection: HttpCourierRepository,
  },
  {
    identifier: 'HttpOrderReturnReferenceWriter',
    dependencies: ['CatalogHttpClient'],
    classInjection: HttpOrderReturnReferenceWriter,
  },
  {
    identifier: 'AssignPreparerHandler',
    dependencies: ['HttpRouteWriter'],
    classInjection: AssignPreparerHandler,
  },
  {
    identifier: 'AssignPreparerPrinterHandler',
    dependencies: ['PrinterWriter'],
    classInjection: AssignPreparerPrinterHandler,
  },
  {
    identifier: 'AssignReturnsPrinterHandler',
    dependencies: ['PrinterWriter'],
    classInjection: AssignReturnsPrinterHandler,
  },
  {
    identifier: 'AssignRouteToTrolleyHandler',
    dependencies: ['HttpRouteWriter'],
    classInjection: AssignRouteToTrolleyHandler,
  },
  {
    identifier: 'AssignManualRouteHandler',
    dependencies: ['HttpRouteWriter'],
    classInjection: AssignManualRouteHandler,
  },
  {
    identifier: 'AssignRouteTrolleyToPickerHandler',
    dependencies: ['HttpRouteWriter'],
    classInjection: AssignRouteTrolleyToPickerHandler,
  },
  {
    identifier: 'PrintRouteDocumentsHandler',
    dependencies: ['HttpRouteWriter'],
    classInjection: PrintRouteDocumentsHandler,
  },
  {
    identifier: 'PrintOrderReturnReferenceHandler',
    dependencies: ['HttpOrderReturnReferenceWriter'],
    classInjection: PrintOrderReturnReferenceHandler,
  },
  {
    identifier: 'LogoutEmployeeHandler',
    dependencies: ['EmployeeWriter'],
    classInjection: LogoutEmployeeHandler,
  },
  {
    identifier: 'CreateEmployeeHandler',
    dependencies: ['EmployeeWriter'],
    classInjection: CreateEmployeeHandler,
  },
  {
    identifier: 'PrintLabelHandler',
    dependencies: ['HttpPalletWriter'],
    classInjection: PrintLabelHandler,
  },
  {
    identifier: 'AddOrderHandler',
    dependencies: ['HttpPalletWriter'],
    classInjection: AddOrderHandler,
  },
  {
    identifier: 'SendPalletsHandler',
    dependencies: ['HttpPalletWriter'],
    classInjection: SendPalletsHandler,
  },
  {
    identifier: 'RemoveOrdersHandler',
    dependencies: ['HttpPalletWriter'],
    classInjection: RemoveOrdersHandler,
  },
  {
    identifier: 'InventoryReferenceHandler',
    dependencies: ['HttpInventoryWriter'],
    classInjection: InventoryReferenceHandler,
  },
  {
    identifier: 'RemoveInventoryHandler',
    dependencies: ['HttpInventoryWriter'],
    classInjection: RemoveInventoryHandler,
  },
  {
    identifier: 'FinalizeInventoryHandler',
    dependencies: ['HttpInventoryWriter'],
    classInjection: FinalizeInventoryHandler,
  },
  {
    identifier: 'FinishOrderReturnHandler',
    dependencies: ['HttpOrderReturnWriter'],
    classInjection: FinishOrderReturnHandler,
  },
  {
    identifier: 'CreateNotificationHandler',
    dependencies: ['NotificationRepository'],
    classInjection: CreateNotificationHandler,
  },
  {
    identifier: 'RemoveNotificationHandler',
    dependencies: ['NotificationRepository'],
    classInjection: RemoveNotificationHandler,
  },
  {
    identifier: 'SendFinishedPalletHandler',
    dependencies: ['HttpPalletWriter'],
    classInjection: SendFinishedPalletHandler,
  },
  {
    identifier: 'LoginEmployeeHandler',
    dependencies: ['Authenticator'],
    classInjection: LoginEmployeeHandler,
  },
  {
    identifier: 'RemoveRoutesHandler',
    dependencies: ['HttpRouteListWriter'],
    classInjection: RemoveRoutesHandler,
  },
];

export { commandRegisterList };
