import React, { useMemo, useRef } from 'react';
import { Icon, IconVariant, Text, TextVariant } from '@lookiero/react-ui-kit';
import { useNavigate, useLocation } from 'react-router';
import REACT_ROUTES from 'ui/_routing/routes';
import useEnvironment from 'ui/views/_behaviors/useEnvironment';
import { useGetCurrentEmployee } from 'ui/views/_behaviors/useGetCurrentEmployee';
import { ReactIntl } from '@lookiero/i18n.js';
import { I18N_MESSAGES, PREFIX } from 'shared/i18n/root';
import { animated, useSpring } from 'react-spring';

import './sideMenu.css';
import { useClickAway } from 'react-use';
import { Logo } from '../../../component-library/molecules/logo/Logo';

interface SideMenuProps {
  readonly onShowSideMenu: () => void;
}

const SideMenu: React.FC<SideMenuProps> = ({ onShowSideMenu }) => {
  const navigate = useNavigate();
  const currentPath = useLocation().pathname;
  const pickingEnabled = useEnvironment().featureToggle.pickingEnabled;
  const employee = useGetCurrentEmployee();
  const sideMenuRef = useRef(null);
  const messages = {
    routes: ReactIntl.useI18nMessage({ prefix: PREFIX, id: I18N_MESSAGES.SIDE_MENU_ROUTES }),
    picking: ReactIntl.useI18nMessage({ prefix: PREFIX, id: I18N_MESSAGES.SIDE_MENU_PICKING }),
    shippingApp: ReactIntl.useI18nMessage({ prefix: PREFIX, id: I18N_MESSAGES.SIDE_MENU_SHIPPING_APP }),
    inventory: ReactIntl.useI18nMessage({ prefix: PREFIX, id: I18N_MESSAGES.SIDE_MENU_INVENTORY }),
    verification: ReactIntl.useI18nMessage({ prefix: PREFIX, id: I18N_MESSAGES.SIDE_MENU_VERIFICATION }),
    returns: ReactIntl.useI18nMessage({ prefix: PREFIX, id: I18N_MESSAGES.SIDE_MENU_RETURNS }),
    unsold: ReactIntl.useI18nMessage({ prefix: PREFIX, id: I18N_MESSAGES.SIDE_MENU_UNSOLD }),
  };

  const onMenuItemClick = (route: REACT_ROUTES) => {
    if (currentPath !== route) {
      navigate(`/${route}`);
      onShowSideMenu();
    }
  };

  const getMenuOptionItemClass = (routeOption: REACT_ROUTES) => {
    if (currentPath === `/${routeOption}`) {
      return 'side-menu__item side-menu__item--active';
    }

    return 'side-menu__item';
  };

  const showInventoryTab = useMemo(() => {
    if (!employee) {
      return false;
    }

    return employee.canInventory();
  }, [employee]);

  const showReturnsTab = useMemo(() => {
    if (!employee) {
      return false;
    }

    return employee.canManageReturns();
  }, [employee]);

  const showUnsoldTab = useMemo(() => {
    if (!employee) {
      return false;
    }
    return employee.canUnsold();
  }, [employee]);

  const translationAnimation = {
    from: { transform: 'translate3d(-100%, 0, 0)', opacity: 1 },
    to: { transform: 'translate3d(0, 0, 0)', opacity: 1 },
    config: { duration: 250 },
  };

  const backgroundOpacityAnimation = {
    from: { opacity: process.env.NODE_ENV === 'test' ? 1 : 0 },
    to: { opacity: 1 },
    config: { duration: 150 },
  };
  const translation = useSpring(translationAnimation);
  const backgroundOpacity = useSpring(backgroundOpacityAnimation);

  useClickAway(sideMenuRef, () => {
    onShowSideMenu();
  });

  const sideMenuTabs = [
    {
      routeTabOption: REACT_ROUTES.ROUTES,
      icon: IconVariant.MARKER,
      tabText: messages.routes,
      iconClassName: 'side-menu__routes-icon',
    },
    {
      routeTabOption: REACT_ROUTES.PICKING,
      icon: IconVariant.TROLLEY,
      tabText: messages.picking,
      canRenderTab: pickingEnabled,
      iconClassName: 'side-menu__picking-icon',
    },
    {
      routeTabOption: REACT_ROUTES.UNSOLD,
      icon: IconVariant.EXPORT,
      tabText: messages.unsold,
      canRenderTab: showUnsoldTab,
      iconClassName: 'side-menu__unsold-icon',
    },
    {
      routeTabOption: REACT_ROUTES.SHIPPING,
      icon: IconVariant.BOX,
      tabText: messages.shippingApp,
    },
    {
      routeTabOption: REACT_ROUTES.INVENTORY,
      icon: IconVariant.SCAN_2,
      tabText: messages.inventory,
      canRenderTab: showInventoryTab,
    },
    {
      routeTabOption: REACT_ROUTES.VERIFICATION,
      icon: IconVariant.CHECK,
      tabText: messages.verification,
      iconClassName: 'side-menu__verification-icon',
    },
    {
      routeTabOption: REACT_ROUTES.RETURNS,
      icon: IconVariant.ORDER,
      tabText: messages.returns,
      canRenderTab: showReturnsTab,
      iconClassName: 'side-menu__returns-icon',
    },
  ];

  const renderTab = ({
    routeTabOption,
    icon,
    tabText,
    canRenderTab = true,
    iconClassName = 'side-menu__icon',
  }: {
    routeTabOption: REACT_ROUTES;
    icon: IconVariant;
    tabText: string;
    canRenderTab?: boolean;
    iconClassName?: string;
  }) =>
    canRenderTab && (
      <div
        key={tabText.split(' ').join('_')}
        className={getMenuOptionItemClass(routeTabOption)}
        onClick={() => onMenuItemClick(routeTabOption)}
      >
        <Icon variant={icon} className={iconClassName} />
        <Text variant={TextVariant.BODY_BOLD}>{tabText}</Text>
      </div>
    );

  if (currentPath !== REACT_ROUTES.LOGIN) {
    return (
      <animated.div className="side-menu" style={backgroundOpacity}>
        <animated.div className="side-menu__column" style={translation} ref={sideMenuRef}>
          <div className="side-menu__header">
            <div onClick={onShowSideMenu}>
              <Icon variant={IconVariant.LIST} />
            </div>
            <Logo className="appheader__svg" />
          </div>
          {sideMenuTabs.map((tabInfo) => renderTab(tabInfo))}
        </animated.div>
      </animated.div>
    );
  }

  return null;
};

export default SideMenu;
