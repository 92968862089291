import { useCallback } from 'react';
import { useDispatch } from '@lookiero/messaging.js';
import LogoutEmployee from 'core/application/employee/logout/LogoutEmployee';

const useLogout = (
  onSuccess: () => void,
): {
  logout: () => void;
} => {
  const dispatch = useDispatch();
  const logout = useCallback(() => {
    dispatch(new LogoutEmployee());
    onSuccess();
  }, [dispatch, onSuccess]);

  return { logout };
};

export default useLogout;
