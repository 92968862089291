import { Query } from '@lookiero/messaging.js';
import { TrolleyIdView } from 'core/domain/model/route/read/RouteView';

class FindRouteWithPreparedTrolley extends Query {
  public readonly trolleyId: TrolleyIdView;

  public constructor(trolleyId: TrolleyIdView) {
    super();
    this.trolleyId = trolleyId;
  }

  /* istanbul ignore next */
  protected messageName(): string {
    return 'FindRouteWithPreparedTrolley';
  }
}

export default FindRouteWithPreparedTrolley;
