import NumberOfLabels from 'core/domain/model/pallet/write/NumberOfLabels';
import { ShippingType } from 'core/domain/model/pallet/write/ShippingType';
import { Command } from '@lookiero/messaging.js';

class PrintLabel extends Command {
  public palletCount: NumberOfLabels;
  public shippingTypes: ShippingType[];

  public constructor(palletCount: NumberOfLabels, shippingTypes: ShippingType[]) {
    super();
    this.palletCount = palletCount;
    this.shippingTypes = shippingTypes;
  }

  /* istanbul ignore next */
  protected messageName(): string {
    return 'PrintLabel';
  }
}

export default PrintLabel;
