const PREFIX = 'verification.';

const I18N_MESSAGES = {
  TABS_IDENTIFY_ORDER: 'tabs.identify_order',

  IDENTIFY_ORDER_REFERENCE_TITLE: 'identify_order.reference_title',
  IDENTIFY_ORDER_PUBLIC_ORDER_ID_TITLE: 'identify_order.public_order_id_title',
  IDENTIFY_ORDER_INTRO_IMAGE_TEXT: 'identify_order.intro_image_text',
  IDENTIFY_ORDER_INTRO_IMAGE_ALT: 'identify_order.intro_image_alt',
  IDENTIFY_ORDER_NO_RESULT_IMAGE_TEXT: 'identify_order.no_result_image_text',
  IDENTIFY_ORDER_NO_RESULT_IMAGE_ALT: 'identify_order.no_result_image_alt',
  IDENTIFY_ORDER_REFERENCE_PLACEHOLDER: 'identify_order.reference_placeholder',
  IDENTIFY_ORDER_REFERENCE_ERROR: 'identify_order.reference_error',
  IDENTIFY_ORDER_REMOVE_BUTTON: 'identify_order.remove_button',

  NOTIFICATION_FIND_ORDER_FAILED: 'notification.find_order_failed',
  NOTIFICATION_FIND_ORDER_FAILED_TITLE: 'notification.find_order_failed_title',
};

export { PREFIX, I18N_MESSAGES };
