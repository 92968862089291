import LoggerEvent from './LoggerEvent';
import LoggerLayer from './LoggerLayer';

interface LoggerErrorParameters {
  readonly layer: LoggerLayer;
  readonly error: Error;
  readonly info?: Record<string, unknown>;
}
/* istanbul ignore file */
class LoggerError extends LoggerEvent {
  public readonly error: Error;
  public readonly info?: Record<string, unknown>;

  public constructor({ layer, error, info }: LoggerErrorParameters) {
    super(layer);
    this.error = error;
    this.info = info;
  }
}

export default LoggerError;
