import EmployeeWriter from 'core/domain/model/employee/write/EmployeeWriter';
import Employee from 'core/domain/model/employee/write/Employee';
import Storage from 'shared/domain/storage/Storage';
import LocalStorageEmployeeType from '../LocalStorageEmployeeType';

class StorageEmployeeWriter implements EmployeeWriter {
  private static readonly EMPLOYEE_KEY = 'employee';

  private readonly storage: Storage;

  constructor(storage: Storage) {
    this.storage = storage;
  }

  public save(employee: Employee): void {
    const employeeToSave = StorageEmployeeWriter.employeeToJson(employee);
    const encodedEmployee = this.encode(employeeToSave);
    this.storage.save(StorageEmployeeWriter.EMPLOYEE_KEY, encodedEmployee);
  }

  public invalidateCurrentEmployee(): Promise<void> {
    this.storage.remove(StorageEmployeeWriter.EMPLOYEE_KEY);
    return Promise.resolve();
  }

  private static employeeToJson(employee: Employee): LocalStorageEmployeeType {
    return {
      id: employee.userId || '',
      authToken: employee.authToken || '',
      username: employee.username || '',
      roles: employee.roles || [],
    };
  }

  private encode(value: unknown): string {
    if (!value) return '';

    return JSON.stringify(value);
  }
}

export default StorageEmployeeWriter;
