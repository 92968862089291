import { Command } from '@lookiero/messaging.js';

class LogoutEmployee extends Command {
  /* istanbul ignore next */
  protected messageName(): string {
    return 'LogoutEmployee';
  }
}

export default LogoutEmployee;
