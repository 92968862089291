import ErrorCode from './ErrorCode';

abstract class DomainError {
  readonly code: ErrorCode;
  readonly message: string;
  readonly name: string;

  public constructor(code: ErrorCode, message: string, name: string) {
    this.code = code;
    this.message = message;
    this.name = name;
  }

  public toString(): string {
    return `${this.name} { code: ${this.code}, message: ${this.message}}`;
  }
}

export default DomainError;
