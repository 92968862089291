import { Icon, IconVariant, Text, TextVariant } from '@lookiero/react-ui-kit';
import { useClickAway } from 'react-use';
import React, { useCallback, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import REACT_ROUTES from 'ui/_routing/routes';
import { useGetCurrentEmployee } from 'ui/views/_behaviors/useGetCurrentEmployee';
import useLogout from './_behaviors/useLogout';
import EmployeeView from 'core/domain/model/employee/read/EmployeeView';
import { ReactIntl } from '@lookiero/i18n.js';
import { I18N_MESSAGES, PREFIX } from 'shared/i18n/root';

import './header.css';
import { Logo } from '../../../component-library/molecules/logo/Logo';

type Messages = {
  [key: string]: string;
};

interface HeaderProps {
  onShowSideMenu: () => void;
}

const Header: React.FC<HeaderProps> = ({ onShowSideMenu }) => {
  const currentPath = useLocation().pathname;
  const currentEmployee = useGetCurrentEmployee();
  const employeeMenuRef = useRef(null);

  const messages: Messages = {
    closeSession: ReactIntl.useI18nMessage({ prefix: PREFIX, id: I18N_MESSAGES.HEADER_CLOSE_SESSION }),
    routes: ReactIntl.useI18nMessage({ prefix: PREFIX, id: I18N_MESSAGES.SIDE_MENU_ROUTES }),
    picking: ReactIntl.useI18nMessage({ prefix: PREFIX, id: I18N_MESSAGES.SIDE_MENU_PICKING }),
    shipping: ReactIntl.useI18nMessage({ prefix: PREFIX, id: I18N_MESSAGES.SIDE_MENU_SHIPPING_APP }),
    inventory: ReactIntl.useI18nMessage({ prefix: PREFIX, id: I18N_MESSAGES.SIDE_MENU_INVENTORY }),
    verification: ReactIntl.useI18nMessage({ prefix: PREFIX, id: I18N_MESSAGES.SIDE_MENU_VERIFICATION }),
    returns: ReactIntl.useI18nMessage({ prefix: PREFIX, id: I18N_MESSAGES.SIDE_MENU_RETURNS }),
    unsold: ReactIntl.useI18nMessage({ prefix: PREFIX, id: I18N_MESSAGES.SIDE_MENU_UNSOLD }),
  };

  const getAvatar = (employee: EmployeeView) => {
    return employee.username.charAt(0).toLocaleUpperCase();
  };

  const [showEmployeeMenu, setShowEmployeeMenu] = useState(false);
  const handleOnOpenEmployeeMenuToggled = useCallback(() => {
    setShowEmployeeMenu(!showEmployeeMenu);
  }, [showEmployeeMenu]);

  const handleLogout = useLogout(() => setShowEmployeeMenu(false));

  useClickAway(employeeMenuRef, () => {
    if (showEmployeeMenu) {
      setShowEmployeeMenu(false);
    }
  });

  const selectedSideMenuTabName = messages[currentPath.slice(1)];

  if (currentPath !== REACT_ROUTES.LOGIN) {
    return (
      <div className="appheader">
        <div className="appheader__logo">
          <div onClick={onShowSideMenu}>
            <Icon variant={IconVariant.LIST} />
          </div>
          <Logo className="appheader__svg" light />
        </div>
        <div className="appheader__side-menu-tab-name">
          <Text variant={TextVariant.HEADING_1}>{selectedSideMenuTabName}</Text>
        </div>
        {currentEmployee && currentEmployee.username && (
          <div className="appheader__employee" onClick={handleOnOpenEmployeeMenuToggled}>
            <div className="appheader__avatar">
              <Text variant={TextVariant.BODY}>{getAvatar(currentEmployee)}</Text>
            </div>
            <Icon variant={IconVariant.MINOR_DROPDOWN} />
          </div>
        )}
        {currentEmployee && showEmployeeMenu && currentEmployee.username && (
          <div className="appheader__employee-menu" ref={employeeMenuRef}>
            <div className="appheader__employee-menu-item appheader__employee-menu-item--header">
              <Text variant={TextVariant.BODY_SMALL}>{currentEmployee.username}</Text>
            </div>
            <div className="appheader__employee-menu-item" onClick={() => handleLogout.logout()}>
              <Icon variant={IconVariant.POWER} className="appheader__logout-icon" />
              <Text variant={TextVariant.BODY_SMALL}>{messages.closeSession}</Text>
            </div>
          </div>
        )}
      </div>
    );
  }

  return null;
};

export default Header;
