import { AggregateRoot } from '@lookiero/messaging.js';
import NotificationLevel from './NotificationLevel';
import NotificationCreated from './event/NotificationCreated';

class Notification extends AggregateRoot {
  readonly id: string;
  readonly level: NotificationLevel;
  readonly content: string;
  readonly timestamp: number;
  readonly contentHeader?: string;

  private constructor(id: string, level: NotificationLevel, content: string, contentHeader?: string) {
    super();
    this.id = id;
    this.level = level;
    this.content = content;
    this.contentHeader = contentHeader;
    this.timestamp = Date.now();
  }

  public static create(id: string, level: NotificationLevel, content: string, contentHeader?: string): Notification {
    const instance = new Notification(id, level, content, contentHeader);
    instance.record(new NotificationCreated(instance.id));

    return instance;
  }
}

export default Notification;
