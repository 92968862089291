import { Query } from '@lookiero/messaging.js';

type FindPalletParameter = {
  readonly palletNumber: string;
};

class FindPallet extends Query {
  public readonly palletNumber: string;

  public constructor({ palletNumber }: FindPalletParameter) {
    super();
    this.palletNumber = palletNumber;
  }

  /* istanbul ignore next */
  protected messageName(): string {
    return 'FindPallet';
  }
}

export default FindPallet;
