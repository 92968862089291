import { CommandHandler, EventBus } from '@lookiero/messaging.js';
import PalletWriter from 'core/domain/model/pallet/write/PalletWriter';
import SendPallet from './SendPallets';
import Pallet from 'core/domain/model/pallet/write/Pallet';
import PalletSent from 'core/domain/model/pallet/write/event/palletsSent/PalletSent';
import PalletsSent from 'core/domain/model/pallet/write/event/palletsSent/PalletsSent';
import SendPalletsFailed from 'core/domain/model/pallet/write/event/sendPalletsFailed/SendPalletsFailed';

class SendPalletsHandler implements CommandHandler<SendPallet> {
  private readonly palletWriter: PalletWriter;
  eventBus!: EventBus;

  public constructor(palletWriter: PalletWriter) {
    this.palletWriter = palletWriter;
  }

  public async execute({ palletIds }: SendPallet): Promise<void> {
    try {
      const palletList: Pallet[] = palletIds.map((palletId) => Pallet.sendPallet(palletId));
      await this.palletWriter.sendPallets(palletList);
      const events = palletList.flatMap((pallet) =>
        pallet.recordedEvents().filter((event) => event instanceof PalletSent),
      ) as PalletSent[];

      this.eventBus.publish([new PalletsSent(events)]);
    } catch (error) {
      this.eventBus.publish([new SendPalletsFailed()]);
    }
  }
}

export default SendPalletsHandler;
