export interface OrderView {
  readonly id: string;
  readonly publicOrderId: string;
  readonly preparationDate: string;
  readonly country: string;
  readonly customer: string;
  readonly numberOfGarments: number;
}

export const ORDER_VIEW_DATE_PATTERN = 'yyyy-MM-dd';
