import { Query } from '@lookiero/messaging.js';

class FindRouteById extends Query {
  public routeId: string;

  constructor(routeId: string) {
    super();
    this.routeId = routeId;
  }

  /* istanbul ignore next */
  protected messageName(): string {
    return 'FindRouteById';
  }
}

export default FindRouteById;
