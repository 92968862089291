import { Query } from '@lookiero/messaging.js';

class FindFinishedPallets extends Query {
  /* istanbul ignore next */
  protected messageName(): string {
    return 'FindFinishedPallets';
  }
}

export default FindFinishedPallets;
