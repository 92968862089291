import NotificationRepository from 'core/domain/model/notification/NotificationRepository';
import { CommandHandler, EventBus } from '@lookiero/messaging.js';
import RemoveNotification from './RemoveNotification';
import NotificationRemoved from 'core/domain/model/notification/event/NotificationRemoved';

class RemoveNotificationHandler implements CommandHandler<RemoveNotification> {
  private readonly repository: NotificationRepository;
  eventBus!: EventBus;

  public constructor(repository: NotificationRepository) {
    this.repository = repository;
  }

  public execute({ notificationId }: RemoveNotification): Promise<void> {
    return this.repository.remove(notificationId).then(() => {
      this.eventBus.publish([new NotificationRemoved(notificationId)]);
    });
  }
}

export default RemoveNotificationHandler;
