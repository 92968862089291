import DomainError from 'shared/domain/error/DomainError';
import ErrorCode from 'shared/domain/error/ErrorCode';
import { I18N_MESSAGES } from 'shared/i18n/returns';

class OrderReturnViewNotFoundError extends DomainError {
  public constructor() {
    super(
      ErrorCode.ORDER_RETURN_VIEW_NOT_FOUND,
      I18N_MESSAGES.ORDER_RETURN_VIEW_NOT_FOUND_ERROR,
      OrderReturnViewNotFoundError.name,
    );
  }
}

export default OrderReturnViewNotFoundError;
