const PREFIX = 'shipping.';

const I18N_MESSAGES = {
  TABS_ITEM_ASSIGN: 'tabs.item_assign',
  TABS_PALLET_LABEL: 'tabs.pallet_label',
  TABS_SENDING: 'tabs.sending',
  TABS_UNSHIPPED_PALLETS: 'tabs.unshipped_pallets',

  ITEM_ASSIGN_TITLE: 'item_assign.title',
  ITEM_ASSIGN_SCAN_ITEM: 'item_assign.scan_item',
  ITEM_ASSIGN_SCAN_ITEM_PLACEHOLDER: 'item_assign.scan_item_placeholder',
  ITEM_ASSIGN_PALLET_ORDER_DUPLICATED_ERROR: 'item_assign.pallet_order_duplicated_error',
  ITEM_ASSIGN_PALLET_ORDER_INVALID_ERROR: 'item_assign.pallet_order_invalid_error',
  ITEM_ASSIGN_PALLET_ORDER_NOT_FOUND_ERROR: 'item_assign.pallet_order_not_found_error',
  ITEM_ASSIGN_SUCCESFUL: 'item_assign.succesful',
  ITEM_ASSIGN_UNSUCCESFUL: 'item_assign.unsuccesful',
  ITEM_ASSIGN_FINISH: 'item_assign.finish',
  ITEM_ASSIGN_UNASSIGN_BUTTON: 'item_assign.unassign_button',
  ITEM_ASSIGN_PALLET_DRAFT: 'item_assign.draft_status',
  ITEM_ASSIGN_PALLET_SHIPPED: 'item_assign.shipped_status',

  UNASSIGN_ITEMS_MODAL_TITLE: 'unassign_items_modal.title',
  UNASSIGN_ITEMS_MODAL_DESCRIPTION: 'unassign_items_modal.description',
  UNASSIGN_ITEMS_MODAL_UNASSIGN_BUTTON: 'unassign_items_modal.unassign_button',
  UNASSIGN_ITEMS_MODAL_CANCEL_BUTTON: 'unassign_items_modal.cancel_button',

  PALLET_LABEL_TITLE: 'pallet_label.title',
  PALLET_LABEL_PALLETS: 'pallet_label.pallets',
  PALLET_LABEL_COURIER_TYPE_PLACEHOLDER: 'pallet_label.courier_type_placeholder',
  PALLET_LABEL_AVAILABLE_LABELS_PLACEHOLDER: 'pallet_label.available_labels_placeholder',
  PALLET_LABEL_LABEL_QUANTITY: 'pallet_label.label_quantity',
  PALLET_LABEL_LABEL_QUANTITY_PLACEHOLDER: 'pallet_label.label_quantity_placeholder',
  PALLET_LABEL_PRINT_LABEL_BUTTON: 'pallet_label.print_label_button',

  SENDING_TITLE: 'sending.title',
  SENDING_INTRO: 'sending.intro',
  SENDING_REGISTERED_PALLETS: 'sending.registered_pallets',
  SENDING_SEND_BUTTON: 'sending.send_button',
  SENDING_PALLET_ALREADY_ADDED_ERROR: 'sending.pallet_already_added_error',
  SENDING_UNASSIGN_BUTTON: 'sending.unassign_button',

  UNASSIGN_PALLETS_MODAL_TITLE: 'unassign_pallets_modal.title',
  UNASSIGN_PALLETS_MODAL_DESCRIPTION: 'unassign_pallets_modal.description',
  UNASSIGN_PALLETS_MODAL_UNASSIGN_BUTTON: 'unassign_pallets_modal.unassign_button',
  UNASSIGN_PALLETS_MODAL_CANCEL_BUTTON: 'unassign_pallets_modal.cancel_button',

  PALLET_SCAN_SCAN_PALLET: 'pallet_scan.scan_pallet',
  PALLET_SCAN_SCAN_PALLET_PLACEHOLDER: 'pallet_scan.scan_pallet_placeholder',
  PALLET_SCAN_PALLET_NOT_FOUND_ERROR: 'pallet_scan.pallet_not_found_error',
  PALLET_SCAN_SHIPPED_PALLET_SENT_ERROR: 'pallet_scan.shipped_pallet_sent_error',
  PALLET_SCAN_DRAFT_PALLET_SENT_ERROR: 'pallet_scan.draft_pallet_sent_error',
  PALLET_SCAN_FINISHED_PALLET_SENT_ERROR: 'pallet_scan.finished_pallet_sent_error',

  NOTIFICATION_ADD_ORDER_FAILED: 'notification.add_order_failed',
  NOTIFICATION_ADD_ORDER_FAILED_TITLE: 'notification.add_order_failed_title',
  NOTIFICATION_PRINT_LABELS_FAILED: 'notification.print_labels_failed',
  NOTIFICATION_PRINT_LABELS_FAILED_TITLE: 'notification.print_labels_failed_title',
  NOTIFICATION_SEND_PALLETS_FAILED: 'notification.send_pallets_failed',
  NOTIFICATION_SEND_PALLETS_FAILED_TITLE: 'notification.send_pallets_failed_title',
  NOTIFICATION_REMOVE_ORDERS_FAILED: 'notification.remove_orders_failed',
  NOTIFICATION_REMOVE_ORDERS_FAILED_TITLE: 'notification.remove_orders_failed_title',
  NOTIFICATION_ORDERS_REMOVED: 'notification.orders_removed',
  NOTIFICATION_PALLET_LABELS_PRINTED: 'notification.pallet_labels_printed',
  NOTIFICATION_PALLETS_SENT: 'notification.pallets_sent',

  UNSHIPPED_PALLETS_TABLE_COLUMN_PALLET: 'unshipped_pallets.table_column_pallet',
  UNSHIPPED_PALLETS_TABLE_COLUMN_COURIER: 'unshipped_pallets.table_column_courier',
  UNSHIPPED_PALLETS_TABLE_COLUMN_DESTINATION: 'unshipped_pallets.table_column_destination',

  PALLET_FINISHED_TITLE: 'pallet.finished.title',
  PALLET_FINISHED_CONTENT: 'pallet.finished.content',
  NOTIFICATION_SEND_FINISH_PALLET_FAILED: 'notification.send_finish_pallet_failed',
  NOTIFICATION_PALLET_SEND_FINISHED: 'notification.pallet_send_finished',
};

export { PREFIX, I18N_MESSAGES };
