import { CommandHandler, EventBus } from '@lookiero/messaging.js';
import RemoveRoutes from './RemoveRoutes';
import RouteListWriter from 'core/domain/model/routeList/write/RouteListWriter';
import Route from 'core/domain/model/routeList/write/Route';
import RouteList from 'core/domain/model/routeList/write/RouteList';

class RemoveRoutesHandler implements CommandHandler<RemoveRoutes> {
  private readonly routeListWriter: RouteListWriter;
  eventBus!: EventBus;

  public constructor(routeListWriter: RouteListWriter) {
    this.routeListWriter = routeListWriter;
  }

  public async execute({ routeIds }: RemoveRoutes): Promise<void> {
    const routes = routeIds.map((id) => Route.createRoute(id));
    const routeList = RouteList.removeRoutes(routes);
    await this.routeListWriter.removeRoutes(routeList);
    this.eventBus.publish([...routeList.recordedEvents()]);
  }
}

export default RemoveRoutesHandler;
