import { CommandHandler, EventBus } from '@lookiero/messaging.js';
import PrinterWriter from 'core/domain/model/printer/write/PrinterWriter';
import Printer from 'core/domain/model/printer/write/Printer';
import AssignReturnsPrinter from './AssignReturnsPrinter';

class AssignReturnsPrinterHandler implements CommandHandler<AssignReturnsPrinter> {
  private readonly printerWriter: PrinterWriter;
  eventBus!: EventBus;

  public constructor(printerWriter: PrinterWriter) {
    this.printerWriter = printerWriter;
  }

  public async execute(command: AssignReturnsPrinter): Promise<void> {
    const printer = Printer.assignReturnsPrinter(command.printerLine);
    await this.printerWriter.saveReturnsPrinter(printer);
    this.eventBus.publish([...printer.recordedEvents()]);
  }
}

export default AssignReturnsPrinterHandler;
