import { Command } from '@lookiero/messaging.js';
import { ReturnedReference } from '../../../domain/model/orderReturn/write/OrderReturn';

class FinishOrderReturn extends Command {
  public readonly orderId: string;
  public readonly references?: ReturnedReference[];

  public constructor(orderIdParam: string, references?: ReturnedReference[]) {
    super();
    this.orderId = orderIdParam;
    this.references = references;
  }

  /* istanbul ignore next */
  protected messageName(): string {
    return 'FinishOrderReturn';
  }
}

export default FinishOrderReturn;
