import { DomainEvent } from '@lookiero/messaging.js';
import Pallet from '../../Pallet';
import PalletSent from './PalletSent';

class PalletsSent extends DomainEvent {
  readonly palletList: Partial<Pallet>[];

  public constructor(palletSentList: PalletSent[]) {
    super();
    this.palletList = palletSentList.map((palletSentEvent) => palletSentEvent.pallet);
  }

  /* istanbul ignore next */
  public messageName(): string {
    return 'PalletsSent';
  }
}

export default PalletsSent;
