import { DomainEvent } from '@lookiero/messaging.js';

class RouteAssignedToPreparer extends DomainEvent {
  public constructor() {
    super();
  }

  /* istanbul ignore next */
  public messageName(): string {
    return 'RouteAssignedToPreparer';
  }
}

export default RouteAssignedToPreparer;
