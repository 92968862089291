import { Command } from '@lookiero/messaging.js';

class AssignManualRoute extends Command {
  public readonly orderId: string;

  public constructor(orderId: string) {
    super();
    this.orderId = orderId;
  }

  /* istanbul ignore next */
  protected messageName(): string {
    return 'AssignManualRoute';
  }
}

export default AssignManualRoute;
