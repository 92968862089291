import HttpClient, { HttpClientResponseError, HttpErrorCodes } from 'shared/domain/delivery/HttpClient';
import OrderReturnReader from 'core/domain/model/orderReturn/read/OrderReturnReader';
import { OrderReturnView } from 'core/domain/model/orderReturn/read/OrderReturnView';
import OrderReturnViewNotFoundError from 'core/domain/model/orderReturn/read/OrderReturnViewNotFoundError';
import { HttpOrderReturnView } from './HttpOrderReturnView';

class HttpOrderReturnReader implements OrderReturnReader {
  private readonly httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async findOrderReturn({
    courierOrderId,
    publicOrderId,
  }: {
    courierOrderId?: string;
    publicOrderId?: string;
  }): Promise<OrderReturnView> {
    const pathParam = courierOrderId ? `courierOrderId=${courierOrderId}` : `publicOrderId=${publicOrderId}`;
    return this.httpClient
      .get<HttpOrderReturnView>(`/v1/orders/${pathParam}`)
      .then((response) => new OrderReturnView(response))
      .catch((error: HttpClientResponseError) => {
        if (error.status === HttpErrorCodes.NOT_FOUND) {
          throw new OrderReturnViewNotFoundError();
        }
        throw error;
      });
  }
}

export default HttpOrderReturnReader;
