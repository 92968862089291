import { Query } from '@lookiero/messaging.js';

class FindPrinters extends Query {
  public constructor() {
    super();
  }

  /* istanbul ignore next */
  protected messageName(): string {
    return 'FindPrinters';
  }
}

export default FindPrinters;
