import { Query } from '@lookiero/messaging.js';

class FindInventory extends Query {
  public readonly location: string;

  public constructor(location: string) {
    super();
    this.location = location;
  }

  /* istanbul ignore next */
  protected messageName(): string {
    return 'FindInventory';
  }
}

export default FindInventory;
