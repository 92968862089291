import Pallet from 'core/domain/model/pallet/write/Pallet';
import PalletOrderDuplicatedError from 'core/domain/model/pallet/write/PalletOrderDuplicatedError';
import PalletOrderInvalidError from 'core/domain/model/pallet/write/PalletOrderInvalidError';
import PalletOrderNotFoundError from 'core/domain/model/pallet/write/PalletOrderNotFoundError';
import PalletWriter from 'core/domain/model/pallet/write/PalletWriter';
import HttpClient, { HttpClientResponseError, HttpErrorCodes } from 'shared/domain/delivery/HttpClient';
import DomainError from 'shared/domain/error/DomainError';

class HttpPalletWriter implements PalletWriter {
  private readonly httpClient: HttpClient;

  private static readonly PALLET_DOMAIN_ERRORS: Record<number, (body: Record<string, unknown>) => DomainError> = {
    [HttpErrorCodes.BAD_REQUEST]: (body: Record<string, unknown>) =>
      new PalletOrderInvalidError(body.publicOrderId as string),
    [HttpErrorCodes.NOT_FOUND]: (body: Record<string, unknown>) =>
      new PalletOrderNotFoundError(body.publicOrderId as string),
    [HttpErrorCodes.CONFLICT]: (body: Record<string, unknown>) =>
      new PalletOrderDuplicatedError(
        body.courierOrderId as string,
        body.palletLabelNumber as number,
        body.palletStatus as string,
      ),
  };

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public printLabels(pallet: Pallet): Promise<void> {
    const body = {
      palletCount: pallet.numberOfLabels?.value,
      shippingTypes: pallet.shippingTypes ? [...pallet.shippingTypes] : undefined,
    };

    return this.httpClient.post<void>('/v1/pallets', body);
  }

  public addOrder(pallet: Pallet): Promise<void> {
    const body = {
      courierOrderId: pallet.orders.length > 0 ? pallet.orders[0].id : undefined,
    };

    return this.httpClient
      .patch<void>(`/v1/pallets/${pallet.palletId}`, body)
      .catch((error: HttpClientResponseError) => this.reThrowAddOrderError(error, body.courierOrderId));
  }

  public removeOrders(pallet: Pallet): Promise<void> {
    const orders = pallet.orders.map((order) => ({
      id: order.id,
    }));
    const body = {
      orders,
    };

    return this.httpClient.put<void>(`/v1/pallets/${pallet.palletId}/removeorders`, body).catch(this.throwPalletError);
  }

  public sendPallets = (palletList: Pallet[]): Promise<void> => {
    const palletsId = palletList.map((pallet) => pallet.palletId);
    const body = {
      palletsId,
    };

    return this.httpClient.patch<void>('/v1/pallets/ship', body);
  };

  public async sendFinishedPallet(pallet: Pallet): Promise<void> {
    const palletId = pallet.palletId || '';
    await this.httpClient.patch<void>(`/v1/pallets/${palletId}/finish`);
  }

  private throwPalletError(error: HttpClientResponseError, errorParams?: Record<string, unknown>) {
    const domainErrorFn = error.status ? HttpPalletWriter.PALLET_DOMAIN_ERRORS[error.status] : undefined;
    const errorToThrow = domainErrorFn ? domainErrorFn(errorParams ? errorParams : {}) : error;
    throw errorToThrow;
  }

  private reThrowAddOrderError(error: HttpClientResponseError, courierId?: string) {
    const errorParams = error.body ? { publicOrderId: courierId, ...error.body } : { publicOrderId: courierId };
    this.throwPalletError(error, errorParams);
  }
}

export default HttpPalletWriter;
