import CourierRepository from 'core/domain/model/courier/CourierRepository';
import CourierView from 'core/domain/model/courier/CourierView';
import HttpClient from 'shared/domain/delivery/HttpClient';

class HttpCourierRepository implements CourierRepository {
  private readonly httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async list(): Promise<CourierView[]> {
    return this.httpClient.get<CourierView[]>('/v1/couriers');
  }
}

export default HttpCourierRepository;
