import { QueryHandler } from '@lookiero/messaging.js';
import FindRouteById from './FindRouteById';
import RouteReader from 'core/domain/model/route/read/RouteReader';
import RouteView from 'core/domain/model/route/read/RouteView';

class FindRouteByIdHandler implements QueryHandler<FindRouteById> {
  private readonly reader: RouteReader;

  constructor(reader: RouteReader) {
    this.reader = reader;
  }

  public handle({ routeId }: FindRouteById): Promise<RouteView> {
    return this.reader.findRouteById(routeId);
  }
}

export default FindRouteByIdHandler;
