import AggregateRoot from 'shared/domain/AggregateRoot';
import FinishOrderReturn from './event/orderReturnFinished/OrderReturnFinished';

export interface ReturnedReference {
  readonly id: string;
  readonly returned: boolean;
}

export class OrderReturn extends AggregateRoot {
  readonly id: string;

  constructor(orderId: string) {
    super();
    this.id = orderId;
  }

  public static finishOrderReturn(orderId: string, references?: ReturnedReference[]): OrderReturn {
    const orderReturn = new OrderReturn(orderId);
    orderReturn.record(new FinishOrderReturn(orderId, references));
    return orderReturn;
  }
}
