import { QueryHandler } from '@lookiero/messaging.js';
import PrinterReader from 'core/domain/model/printer/read/PrinterReader';
import { PrinterView } from 'core/domain/model/printer/read/PrinterView';
import FindPrinters from './FindPrinters';

class FindPrintersHandler implements QueryHandler<FindPrinters> {
  private readonly printerReader: PrinterReader;

  public constructor(printerReader: PrinterReader) {
    this.printerReader = printerReader;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public handle(query: FindPrinters): Promise<PrinterView[]> {
    return this.printerReader.findPrinters();
  }
}

export default FindPrintersHandler;
