import Authenticator from 'core/domain/model/employee/write/Authenticator';
import LoginEmployee from 'core/application/employee/login/LoginEmployee';
import { CommandHandler, EventBus } from '@lookiero/messaging.js';
import EmployeeAuthenticated from 'core/domain/model/employee/write/event/createEmployeeWhenAuthenticated/EmployeeAuthenticated';

class LoginEmployeeHandler implements CommandHandler<LoginEmployee> {
  private readonly authenticator: Authenticator;
  eventBus!: EventBus;

  public constructor(authenticator: Authenticator) {
    this.authenticator = authenticator;
  }

  public async execute({ credentials }: LoginEmployee): Promise<void> {
    const authResult = await this.authenticator.auth(credentials);
    this.eventBus.publish([new EmployeeAuthenticated({ ...authResult, username: credentials.username })]);
  }
}

export default LoginEmployeeHandler;
