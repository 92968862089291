import { Command } from '@lookiero/messaging.js';

class AddOrder extends Command {
  public palletId: string;
  public orderCourierId: string;

  public constructor(palletId: string, orderCourierId: string) {
    super();
    this.palletId = palletId;
    this.orderCourierId = orderCourierId;
  }

  /* istanbul ignore next */
  protected messageName(): string {
    return 'AddOrder';
  }
}

export default AddOrder;
