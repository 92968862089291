import { ORDER_VIEW_DATE_PATTERN, OrderView } from 'core/domain/model/order/read/OrderView';
import { format } from 'date-fns';

export interface HttpWorkOrderView {
  readonly id: string;
  readonly publicOrderId: string;
  readonly preparationDate: string;
  readonly country: string;
  readonly user: HttpWorkOrderUserView;
  readonly items: Record<string, unknown>[];
}

interface HttpWorkOrderUserView {
  name: string;
  surname: string;
}

export const httpWorkOrderViewToDomain = (order: HttpWorkOrderView): OrderView => {
  return {
    id: order.id,
    publicOrderId: order.publicOrderId,
    country: order.country,

    preparationDate: toDomainDateFormatted(order.preparationDate),
    customer: `${order.user.name} ${order.user.surname}`,
    numberOfGarments: order.items.length,
  };
};

const toDomainDateFormatted = (dateAsString: string): string => {
  return format(new Date(dateAsString), ORDER_VIEW_DATE_PATTERN);
};
