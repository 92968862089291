import { DomainEvent } from '@lookiero/messaging.js';
import Order from 'core/domain/model/pallet/write/Order';

class OrdersRemoved extends DomainEvent {
  readonly orders: Order[];

  public constructor(ordersParam: Order[]) {
    super();
    this.orders = [...ordersParam];
  }

  /* istanbul ignore next */
  public messageName(): string {
    return 'OrdersRemoved';
  }
}

export default OrdersRemoved;
