import DomainError from 'shared/domain/error/DomainError';
import ErrorCode from 'shared/domain/error/ErrorCode';
import { I18N_MESSAGES } from 'shared/i18n/shipping';

class PalletOrderDuplicatedError extends DomainError {
  readonly courierOrderId: string;
  readonly palletLabelNumber: number;
  readonly palletStatus: string;

  public constructor(courierOrderId: string, palletLabelNumber: number, palletStatus: string) {
    super(
      ErrorCode.PALLET_ORDER_DUPLICATED,
      I18N_MESSAGES.ITEM_ASSIGN_PALLET_ORDER_DUPLICATED_ERROR,
      PalletOrderDuplicatedError.name,
    );
    this.courierOrderId = courierOrderId;
    this.palletLabelNumber = palletLabelNumber;
    this.palletStatus = palletStatus;
  }
}

export default PalletOrderDuplicatedError;
