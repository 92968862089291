import DomainError from 'shared/domain/error/DomainError';
import ErrorCode from 'shared/domain/error/ErrorCode';
import { I18N_MESSAGES } from 'shared/i18n/returns';

class FinishOrderReturnFailedError extends DomainError {
  public constructor() {
    super(ErrorCode.FINISH_ORDER_RETURN, I18N_MESSAGES.FINISH_ORDER_RETURN_ERROR, FinishOrderReturnFailedError.name);
  }
}

export default FinishOrderReturnFailedError;
