import { v4 as uuid } from 'uuid';
import Logger from 'core/domain/model/logger/Logger';
import LoggerError from 'core/domain/model/logger/LoggerError';
import LoggerDomainEvent from 'core/domain/model/logger/LoggerDomainEvent';

/* eslint-disable @typescript-eslint/no-explicit-any */
/* istanbul ignore file */

class ConsoleLogger implements Logger {
  private readonly console: Console;

  public constructor(aConsole: Console) {
    this.console = aConsole;
  }

  public error({ layer, error, info }: LoggerError): string {
    const id = uuid();

    this.console.error(layer, error, info);

    return id;
  }

  public event({ layer, event }: LoggerDomainEvent): void {
    this.console.log(layer, event);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public report(id: string): void {
    //
  }
}

export default ConsoleLogger;
