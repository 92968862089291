import DomainError from 'shared/domain/error/DomainError';
import ErrorCode from 'shared/domain/error/ErrorCode';
import { I18N_MESSAGES } from 'shared/i18n/picking';

class RouteWithTrolleyError extends DomainError {
  public constructor() {
    super(ErrorCode.ROUTE_WITH_TROLLEY, I18N_MESSAGES.ROUTE_WITH_TROLLEY_NOT_FOUND_ERROR, RouteWithTrolleyError.name);
  }
}

export default RouteWithTrolleyError;
