import AggregateRoot from 'shared/domain/AggregateRoot';
import PalletLabelsPrinted from 'core/domain/model/pallet/write/event/palletLabelsPrinted/PalletLabelsPrinted';
import { ShippingType } from './ShippingType';
import NumberOfLabels from './NumberOfLabels';
import Order from 'core/domain/model/pallet/write/Order';
import OrderAdded from 'core/domain/model/pallet/write/event/orderAdded/OrderAdded';
import PalletSent from './event/palletsSent/PalletSent';
import OrdersRemoved from './event/ordersRemoved/OrdersRemoved';
import PalletSendFinished from './event/palletSendFinished/PalletSendFinished';

class Pallet extends AggregateRoot {
  readonly numberOfLabels?: NumberOfLabels;
  readonly shippingTypes?: ShippingType[];
  readonly palletId?: string;
  readonly orders: Order[];

  private constructor(
    numberOfLabelsParam?: NumberOfLabels,
    shippingTypesParam?: ShippingType[],
    palletIdParam?: string,
    ordersParam?: Order[],
  ) {
    super();
    this.numberOfLabels = numberOfLabelsParam;
    this.shippingTypes = shippingTypesParam;
    this.palletId = palletIdParam;
    this.orders = ordersParam ? [...ordersParam] : [];
  }

  public static printPalletLabels(numberOfLabels: NumberOfLabels, shippingTypes: ShippingType[]): Pallet {
    const pallet = new Pallet(numberOfLabels, shippingTypes);
    pallet.record(new PalletLabelsPrinted(pallet));
    return pallet;
  }

  public static addOrder(palletId: string, order: Order): Pallet {
    const pallet = new Pallet(undefined, undefined, palletId, [order]);
    pallet.record(new OrderAdded(order));
    return pallet;
  }

  public static removeOrders(palletId: string, orders: Order[]): Pallet {
    const pallet = new Pallet(undefined, undefined, palletId, orders);
    pallet.record(new OrdersRemoved(orders));
    return pallet;
  }

  public static sendPallet(palletId: string): Pallet {
    const pallet = new Pallet(undefined, undefined, palletId, undefined);
    pallet.record(new PalletSent(pallet));
    return pallet;
  }

  public static sendFinishedPallet(palletId: string): Pallet {
    const pallet = new Pallet(undefined, undefined, palletId, undefined);
    pallet.record(new PalletSendFinished(pallet));
    return pallet;
  }
}

export default Pallet;
