import RouteView from 'core/domain/model/route/read/RouteView';
import { QueryHandler } from '@lookiero/messaging.js';
import RouteReader from 'core/domain/model/route/read/RouteReader';
import GetPreparerRoute from './GetPreparerRoute';

class GetPreparerRouteHandler implements QueryHandler<GetPreparerRoute> {
  private readonly reader: RouteReader;

  public constructor(reader: RouteReader) {
    this.reader = reader;
  }

  public handle(query: GetPreparerRoute): Promise<RouteView> {
    return this.reader.findPreparerRoute(query.preparerId);
  }
}

export default GetPreparerRouteHandler;
