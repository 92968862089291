import DomainError from 'shared/domain/error/DomainError';
import ErrorCode from 'shared/domain/error/ErrorCode';
import { I18N_MESSAGES } from 'shared/i18n/inventory';

class InventoryLocationInvalidError extends DomainError {
  public constructor() {
    super(
      ErrorCode.INVENTORY_LOCATION_INVALID,
      I18N_MESSAGES.INVENTORY_ACTIONS_TEMPLATE_LOCATION_ERROR,
      InventoryLocationInvalidError.name,
    );
  }
}

export default InventoryLocationInvalidError;
