import { CommandHandler, EventBus } from '@lookiero/messaging.js';
import { OrderReturn } from 'core/domain/model/orderReturn/write/OrderReturn';
import OrderReturnWriter from 'core/domain/model/orderReturn/write/OrderReturnWriter';
import FinishOrderReturn from './FinishOrderReturn';

class FinishOrderReturnHandler implements CommandHandler<FinishOrderReturn> {
  private readonly orderReturnWriter: OrderReturnWriter;
  eventBus!: EventBus;

  public constructor(orderReturnWriter: OrderReturnWriter) {
    this.orderReturnWriter = orderReturnWriter;
  }

  public async execute({ orderId, references }: FinishOrderReturn): Promise<void> {
    const orderReturn = OrderReturn.finishOrderReturn(orderId, references);

    await this.orderReturnWriter.finishOrderReturn(orderReturn, references);
    this.eventBus.publish([...orderReturn.recordedEvents()]);
  }
}

export default FinishOrderReturnHandler;
