const PREFIX = 'error_reporter.';

const I18N_MESSAGES = {
  TITLE_CONTENT: 'title.content',
  DESCRIPTION_LINE_1: 'description.line_1',
  DESCRIPTION_LINE_2: 'description.line_2',
  BUTTON_REPORT: 'button.report',
};

export { PREFIX, I18N_MESSAGES };
