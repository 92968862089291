import { CommandHandler, EventBus } from '@lookiero/messaging.js';
import InventoryWriter from 'core/domain/model/inventory/write/InventoryWriter';
import Location from 'core/domain/model/inventory/write/Location';
import Inventory from 'core/domain/model/inventory/write/Inventory';
import FinalizeInventory from './FinalizeInventory';

class FinalizeInventoryHandler implements CommandHandler<FinalizeInventory> {
  private readonly inventoryWriter: InventoryWriter;
  eventBus!: EventBus;

  public constructor(inventoryWriter: InventoryWriter) {
    this.inventoryWriter = inventoryWriter;
  }

  public async execute({ location }: FinalizeInventory): Promise<void> {
    const locationDomain = Location.createLocation(location);
    const inventory = Inventory.finalizeInventory(locationDomain);

    await this.inventoryWriter.finalizeInventory(inventory);
    this.eventBus.publish([...inventory.recordedEvents()]);
  }
}

export default FinalizeInventoryHandler;
