import { CommandHandler, EventBus } from '@lookiero/messaging.js';
import AddOrder from './AddOrder';
import PalletWriter from 'core/domain/model/pallet/write/PalletWriter';
import Order from 'core/domain/model/pallet/write/Order';
import Pallet from 'core/domain/model/pallet/write/Pallet';

class AddOrderHandler implements CommandHandler<AddOrder> {
  private readonly palletWriter: PalletWriter;
  eventBus!: EventBus;

  public constructor(palletWriter: PalletWriter) {
    this.palletWriter = palletWriter;
  }

  public async execute({ palletId, orderCourierId }: AddOrder): Promise<void> {
    const newOrder = Order.createOrder(orderCourierId);
    const pallet = Pallet.addOrder(palletId, newOrder);

    await this.palletWriter.addOrder(pallet);
    this.eventBus.publish([...pallet.recordedEvents()]);
  }
}

export default AddOrderHandler;
