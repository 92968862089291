import { Query } from '@lookiero/messaging.js';

class GetOrdersWithPublicOrderId extends Query {
  public readonly publicOrderIds: string[];

  public constructor(publicOrderIds: string[]) {
    super();
    this.publicOrderIds = publicOrderIds;
  }

  /* istanbul ignore next */
  protected messageName(): string {
    return 'GetOrdersWithPublicOrderId';
  }
}

export default GetOrdersWithPublicOrderId;
