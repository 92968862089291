import { CommandHandler, EventBus } from '@lookiero/messaging.js';
import PalletWriter from 'core/domain/model/pallet/write/PalletWriter';
import Pallet from 'core/domain/model/pallet/write/Pallet';
import SendFinishedPallet from './SendFinishedPallet';
import PalletSendFinishFailed from 'core/domain/model/pallet/write/event/palletSendFinishFailed/PalletSendFinishFailed';

class SendFinishedPalletHandler implements CommandHandler<SendFinishedPallet> {
  private readonly palletWriter: PalletWriter;
  eventBus!: EventBus;

  public constructor(palletWriter: PalletWriter) {
    this.palletWriter = palletWriter;
  }

  public async execute({ palletId }: SendFinishedPallet): Promise<void> {
    try {
      const pallet = Pallet.sendFinishedPallet(palletId);
      await this.palletWriter.sendFinishedPallet(pallet);

      this.eventBus.publish([...pallet.recordedEvents()]);
    } catch (error) {
      this.eventBus.publish([new PalletSendFinishFailed()]);
    }
  }
}

export default SendFinishedPalletHandler;
