import DomainError from 'shared/domain/error/DomainError';
import ErrorCode from 'shared/domain/error/ErrorCode';
import { I18N_MESSAGES } from 'shared/i18n/shipping';

class PalletOrderInvalidError extends DomainError {
  readonly publicOrderId?: string;

  public constructor(publicOrderId?: string) {
    super(
      ErrorCode.PALLET_ORDER_INVALID,
      I18N_MESSAGES.ITEM_ASSIGN_PALLET_ORDER_INVALID_ERROR,
      PalletOrderInvalidError.name,
    );
    this.publicOrderId = publicOrderId;
  }
}

export default PalletOrderInvalidError;
