import { Command } from '@lookiero/messaging.js';

class InventoryReference extends Command {
  readonly location: string;
  readonly barcode: string;
  readonly quantity: number;

  public constructor(location: string, barcode: string, quantity: number) {
    super();
    this.location = location;
    this.barcode = barcode;
    this.quantity = quantity;
  }

  /* istanbul ignore next */
  protected messageName(): string {
    return 'InventoryReference';
  }
}

export default InventoryReference;
