import React, { useEffect } from 'react';
import Notifications from 'ui/component-library/organisms/notification/Notifications';
import { NotificationProjection } from 'core/domain/model/notification/NotificationProjection';
import useListNotifications from './_behaviors/useListNotifications';
import useRemoveNotification from './_behaviors/useRemoveNotification';

const DEFAULT_DURATION = 10000;

const NotificationsView: React.FC = () => {
  const notifications = useListNotifications();
  const removeNotification = useRemoveNotification();

  useEffect(() => {
    const now = Date.now();
    const timeouts = notifications.map((notification: NotificationProjection) =>
      setTimeout(() => removeNotification(notification), Math.max(0, notification.timestamp + DEFAULT_DURATION - now)),
    );

    return (): void => timeouts.forEach((timeout: NodeJS.Timeout) => clearTimeout(timeout));
  }, [notifications, removeNotification]);

  return <Notifications notifications={notifications} onCancel={removeNotification} />;
};

export default NotificationsView;
