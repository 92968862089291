import React from 'react';
import { Dark, Light } from './Logo.base64';

type LogoProperties = {
  light?: boolean;
  className?: string;
};

const Logo: React.FC<LogoProperties> = ({ light, className }) => (
  <img className={className} src={light ? Light : Dark} alt="Logo" />
);

export { Logo };
