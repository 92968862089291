import { useDispatch } from '@lookiero/messaging.js';
import { useCallback } from 'react';
import { NotificationProjection } from 'core/domain/model/notification/NotificationProjection';
import RemoveNotification from 'core/application/notification/removeNotification/RemoveNotification';

const useRemoveNotification = (): ((notification: NotificationProjection) => void) => {
  const dispatch = useDispatch();

  return useCallback(
    (notification: NotificationProjection) => dispatch(new RemoveNotification(notification.id)),
    [dispatch],
  );
};

export default useRemoveNotification;
