import { CommandHandler } from '@lookiero/messaging.js';
import ThrowError from './ThrowError';
import ErrorRepository from 'shared/domain/error/model/ErrorRepository';
import CoreError from 'shared/domain/error/model/CoreError';

class ThrowErrorHandler implements CommandHandler<ThrowError> {
  private repository: ErrorRepository;

  public constructor(repository: ErrorRepository) {
    this.repository = repository;
  }

  public execute({ error }: ThrowError): Promise<void> {
    const coreError = CoreError.throw(error);

    return this.repository.throw(coreError);
  }
}

export default ThrowErrorHandler;
