import DomainError from 'shared/domain/error/DomainError';
import ErrorCode from 'shared/domain/error/ErrorCode';
import { I18N_MESSAGES } from 'shared/i18n/picking';

class ManualRouteAssignedToPickerError extends DomainError {
  public constructor() {
    super(
      ErrorCode.MANUAL_ROUTE_ASSIGNED_TO_PICKER,
      I18N_MESSAGES.MANUAL_ROUTE_ASSIGNED_TO_PICKER,
      ManualRouteAssignedToPickerError.name,
    );
  }
}

export default ManualRouteAssignedToPickerError;
