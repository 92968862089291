import NotificationLevel from 'core/domain/model/notification/NotificationLevel';
import { Command } from '@lookiero/messaging.js';

class CreateNotification extends Command {
  public readonly notificationId: string;
  public readonly level: NotificationLevel;
  public readonly content: string;
  public readonly contentHeader?: string;

  public constructor(notificationId: string, level: NotificationLevel, content: string, contentHeader?: string) {
    super();
    this.notificationId = notificationId;
    this.level = level;
    this.content = content;
    this.contentHeader = contentHeader;
  }

  /* istanbul ignore next */
  protected messageName(): string {
    return 'CreateNotification';
  }
}

export default CreateNotification;
