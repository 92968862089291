import { QueryHandler } from '@lookiero/messaging.js';
import OrderReader from 'core/domain/model/order/read/OrderReader';
import { OrderView } from 'core/domain/model/order/read/OrderView';
import FindOrder from './FindOrder';

class FindOrderHandler implements QueryHandler<FindOrder> {
  private readonly repository: OrderReader;

  public constructor(repository: OrderReader) {
    this.repository = repository;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public handle(query: FindOrder): Promise<OrderView[]> {
    return this.repository.findOrdersByBarcodes(query.barcodes);
  }
}

export default FindOrderHandler;
