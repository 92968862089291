import { DomainEvent } from '@lookiero/messaging.js';
import Location from '../../Location';

class InventoryRemoved extends DomainEvent {
  readonly location: Location;

  public constructor(location: Location) {
    super();
    this.location = location;
  }

  /* istanbul ignore next */
  public messageName(): string {
    return 'InventoryRemoved';
  }
}

export default InventoryRemoved;
