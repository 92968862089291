import { Command } from '@lookiero/messaging.js';

class AssignRouteTrolleyToPicker extends Command {
  public readonly trolleyId: string;
  public readonly pickerId: string;

  public constructor(trolleyId: string, pickerId: string) {
    super();
    this.trolleyId = trolleyId;
    this.pickerId = pickerId;
  }

  /* istanbul ignore next */
  protected messageName(): string {
    return 'AssignRouteTrolleyToPicker';
  }
}

export default AssignRouteTrolleyToPicker;
