const PREFIX = 'inventory.';

const I18N_MESSAGES = {
  TABS_ACTIONS: 'tabs.actions',
  INVENTORY_ACTIONS_TEMPLATE_LOCATION: 'inventory_actions_template.location',
  INVENTORY_ACTIONS_TEMPLATE_LOCATION_PLACEHOLDER: 'inventory_actions_template.location_placeholder',
  INVENTORY_ACTIONS_TEMPLATE_LOCATION_ERROR: 'inventory_actions_template.location_error',
  INVENTORY_ACTIONS_TEMPLATE_REFERENCE: 'inventory_actions_template.reference',
  INVENTORY_ACTIONS_TEMPLATE_REFERENCE_PLACEHOLDER: 'inventory_actions_template.reference_placeholder',
  INVENTORY_ACTIONS_TEMPLATE_REFERENCE_ERROR: 'inventory_actions_template.reference_error',
  INVENTORY_ACTIONS_TEMPLATE_FINALIZE_BUTTON: 'inventory_actions_template.finalize_button',
  REFERENCE_LIST_HEADER_CODE: 'reference_list_header.code',
  REFERENCE_LIST_HEADER_REFERENCE: 'reference_list_header.reference',
  REFERENCE_LIST_HEADER_QUANTITY: 'reference_list_header.quantity',
  REMOVE_REFERENCES_MODAL_TITLE: 'remove_references_modal.title',
  REMOVE_REFERENCES_MODAL_DESCRIPTION: 'remove_references_modal.description',
  REMOVE_REFERENCES_MODAL_QUESTION: 'remove_references_modal.question',
  REMOVE_REFERENCES_MODAL_CANCEL_BUTTON: 'remove_references_modal.cancel_button',
  REMOVE_REFERENCES_MODAL_OK_BUTTON: 'remove_references_modal.ok_button',
  FINALIZE_INVENTORY_MODAL_TITLE: 'finalize_inventory_modal.title',
  FINALIZE_INVENTORY_MODAL_DESCRIPTION: 'finalize_inventory_modal.description',
  FINALIZE_INVENTORY_MODAL_QUESTION: 'finalize_inventory_modal.question',
  FINALIZE_INVENTORY_MODAL_CANCEL_BUTTON: 'finalize_inventory_modal.cancel_button',
  FINALIZE_INVENTORY_MODAL_OK_BUTTON: 'finalize_inventory_modal.ok_button',
  NOTIFICATION_FIND_INVENTORY_FAILED: 'notification.find_inventory_failed',
  NOTIFICATION_FIND_INVENTORY_FAILED_TITLE: 'notification.find_inventory_failed_title',
  NOTIFICATION_FINALIZE_INVENTORY_FAILED: 'notification.finalize_inventory_failed',
  NOTIFICATION_FINALIZE_INVENTORY_FAILED_TITLE: 'notification.finalize_inventory_failed_title',
  NOTIFICATION_INVENTORY_REFERENCE_FAILED: 'notification.inventory_reference_failed',
  NOTIFICATION_INVENTORY_REFERENCE_FAILED_TITLE: 'notification.inventory_reference_failed_title',
  NOTIFICATION_REMOVE_INVENTORY_FAILED: 'notification.remove_inventory_failed',
  NOTIFICATION_REMOVE_INVENTORY_FAILED_TITLE: 'notification.remove_inventory_failed_title',
  NOTIFICATION_INVENTORY_FINALIZED: 'notification.inventory_finalized',
  NOTIFICATION_INVENTORY_FINALIZED_TITLE: 'notification.inventory_finalized_title',
  NOTIFICATION_INVENTORY_REMOVED: 'notification.inventory_removed',
  NOTIFICATION_INVENTORY_REMOVED_TITLE: 'notification.inventory_removed_title',
};

export { PREFIX, I18N_MESSAGES };
