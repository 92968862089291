import { DomainEvent } from '@lookiero/messaging.js';
import { ReturnedReference } from '../../OrderReturn';

class OrderReturnFinished extends DomainEvent {
  readonly orderId: string;
  readonly references?: ReturnedReference[];

  public constructor(orderIdParam: string, references?: ReturnedReference[]) {
    super();
    this.orderId = orderIdParam;
    this.references = references;
  }

  /* istanbul ignore next */
  public messageName(): string {
    return 'OrderReturnFinished';
  }
}

export default OrderReturnFinished;
