import { RouteType } from 'core/domain/model/route/read/RouteType';
import { RouteStatusFilter } from 'core/domain/model/route/read/RouteStatusFilter';

class FindRouteQueryParams {
  readonly pickerId?: string;
  readonly routeForTypes?: RouteType[];
  readonly assigned?: boolean;
  readonly status?: string;

  constructor(pickerId: string, statusFilter: RouteStatusFilter, routeTypes: RouteType[]) {
    this.pickerId = pickerId !== '' ? pickerId : undefined;
    this.routeForTypes = routeTypes.length > 0 ? routeTypes : undefined;
    this.assigned = statusFilter === RouteStatusFilter.ASSIGNED || undefined;
    this.status = this.statusFilterToStatusValue.get(statusFilter);
  }

  private statusFilterToStatusValue = new Map([
    [RouteStatusFilter.UNASSIGNED, 'READY'],
    [RouteStatusFilter.PLANNING, 'PLANNING'],
    [RouteStatusFilter.PLANNED, 'PLANNED'],
  ]);
}

export default FindRouteQueryParams;
