import { QueryHandler } from '@lookiero/messaging.js';
import FindFinishedPallets from './FindFinishedPallets';
import { PalletMinimalView } from 'core/domain/model/pallet/read/PalletView';
import PalletReader from 'core/domain/model/pallet/read/PalletReader';

class FindFinishedPalletsHandler implements QueryHandler<FindFinishedPallets> {
  private readonly repository: PalletReader;

  public constructor(repository: PalletReader) {
    this.repository = repository;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async handle(query: FindFinishedPallets): Promise<PalletMinimalView[]> {
    return this.repository.findFinishedPallets();
  }
}

export default FindFinishedPalletsHandler;
