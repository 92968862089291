import { CommandHandler, EventBus } from '@lookiero/messaging.js';
import InventoryReference from './InventoryReference';
import InventoryWriter from 'core/domain/model/inventory/write/InventoryWriter';
import DomainError from 'shared/domain/error/DomainError';
import ReferenceQuantity from 'core/domain/model/inventory/write/ReferenceQuantity';
import Location from 'core/domain/model/inventory/write/Location';
import Inventory from 'core/domain/model/inventory/write/Inventory';
import InventoryReferenceFailed from 'core/domain/model/inventory/write/event/inventoryReferenceFailed/InventoryReferenceFailed';

class InventoryReferenceHandler implements CommandHandler<InventoryReference> {
  private readonly inventoryWriter: InventoryWriter;
  eventBus!: EventBus;

  public constructor(inventoryWriter: InventoryWriter) {
    this.inventoryWriter = inventoryWriter;
  }

  public async execute({ location, barcode, quantity }: InventoryReference): Promise<void> {
    const locationDomain = Location.createLocation(location);
    const referenceQuantity = ReferenceQuantity.createReferenceQuantity(barcode, quantity);
    const inventory = Inventory.inventoryReference(locationDomain, referenceQuantity);

    try {
      await this.inventoryWriter.inventoryReference(inventory);
      this.eventBus.publish([...inventory.recordedEvents()]);
    } catch (error) {
      if (error instanceof DomainError) {
        throw error;
      } else {
        this.eventBus.publish([new InventoryReferenceFailed()]);
      }
    }
  }
}

export default InventoryReferenceHandler;
