class Order {
  readonly id: string;

  private constructor(idParam: string) {
    this.id = idParam;
  }

  public static createOrder(idParam: string): Order {
    return new Order(idParam);
  }
}

export default Order;
