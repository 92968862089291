import Storage from 'shared/domain/storage/Storage';

class LocalStorage implements Storage {
  private readonly storageName: string;

  public constructor(storageName: string) {
    this.storageName = storageName;
  }
  private getStorageKey(key: string): string {
    return `${this.storageName}.${key}`;
  }
  public get(key: string): string | null {
    const storageKey = this.getStorageKey(key);
    return localStorage.getItem(storageKey);
  }

  public save(key: string, value: string): void {
    const storageKey = this.getStorageKey(key);
    localStorage.setItem(storageKey, value);
  }

  public remove(key: string): void {
    const storageKey = this.getStorageKey(key);
    localStorage.removeItem(storageKey);
  }

  public has(key: string): boolean {
    const storageKey = this.getStorageKey(key);
    const exist = localStorage.getItem(storageKey) !== null;
    return exist;
  }
}

export default LocalStorage;
