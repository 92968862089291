import { CommandHandler, EventBus } from '@lookiero/messaging.js';
import RemoveInventory from 'core/application/inventory/removeInventory/RemoveInventory';
import InventoryWriter from 'core/domain/model/inventory/write/InventoryWriter';
import Location from 'core/domain/model/inventory/write/Location';
import Inventory from 'core/domain/model/inventory/write/Inventory';

class RemoveInventoryHandler implements CommandHandler<RemoveInventory> {
  private readonly inventoryWriter: InventoryWriter;
  eventBus!: EventBus;

  public constructor(inventoryWriter: InventoryWriter) {
    this.inventoryWriter = inventoryWriter;
  }

  public async execute({ location }: RemoveInventory): Promise<void> {
    const locationDomain = Location.createLocation(location);
    const inventory = Inventory.removeInventory(locationDomain);

    await this.inventoryWriter.removeInventory(inventory);
    this.eventBus.publish([...inventory.recordedEvents()]);
  }
}

export default RemoveInventoryHandler;
