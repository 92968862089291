import { QueryHandler } from '@lookiero/messaging.js';
import OrderReader from 'core/domain/model/order/read/OrderReader';
import { OrderView } from 'core/domain/model/order/read/OrderView';
import GetOrdersWithPublicOrderId from './GetOrdersWithPublicOrderId';

class GetOrdersWithPublicOrderIdHandler implements QueryHandler<GetOrdersWithPublicOrderId> {
  private readonly repository: OrderReader;

  public constructor(repository: OrderReader) {
    this.repository = repository;
  }

  public handle(query: GetOrdersWithPublicOrderId): Promise<OrderView[]> {
    return this.repository.getOrdersByPublicOrderId(query.publicOrderIds);
  }
}

export default GetOrdersWithPublicOrderIdHandler;
