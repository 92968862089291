import FindCouriersHandler from 'core/application/courier/findCouriers/FindCouriersHandler';
import CheckCurrentEmployeeIsLoggedInHandler from 'core/application/employee/checkCurrentEmployeeIsLoggedIn/CheckCurrentEmployeeIsLoggedInHandler';
import GetCurrentEmployeeHandler from 'core/application/employee/getCurrentEmployee/GetCurrentEmployeeHandler';
import FindInventoryHandler from 'core/application/inventory/findInventory/FindInventoryHandler';
import ListNotificationsHandler from 'core/application/notification/listNotifications/ListNotificationsHandler';
import FindOrderHandler from 'core/application/order/FindOrderHandler';
import GetOrdersWithPublicOrderIdHandler from 'core/application/order/getOrdersWithPublicOrderId/GetOrdersWithPublicOrderIdHandler';
import GetUnsoldOrdersHandler from 'core/application/order/getUnsoldOrders/GetUnsoldOrdersHandler';
import FindOrderReturnHandler from 'core/application/orderReturn/findOrderReturn/FindOrderReturnHandler';
import FindDraftPalletsHandler from 'core/application/pallet/findDraftPallets/FindDraftPalletsHandler';
import FindFinishedPallets from 'core/application/pallet/findFinishedPallets/FindFinishedPallets';
import FindFinishedPalletsHandler from 'core/application/pallet/findFinishedPallets/FindFinishedPalletsHandler';
import FindPalletHandler from 'core/application/pallet/findPallet/FindPalletHandler';
import FindPrintersHandler from 'core/application/printer/findPrinters/FindPrintersHandler';
import FindRouteByIdHandler from 'core/application/route/findRouteById/FindRouteByIdHandler';
import FindRoutesHandler from 'core/application/route/findRoutes/FindRoutesHandler';
import FindRoutesCountHandler from 'core/application/route/findRoutesCount/FindRoutesCountHandler';
import FindRouteWithPreparedTrolleyHandler from 'core/application/route/findRouteWithPreparedTrolley/FindRouteWithPreparedTrolleyHandler';
import GetPreparerRouteHandler from 'core/application/route/getPreparerRoute/GetPreparerRouteHandler';
import GetRouteItemHandler from 'core/application/routeItem/getRouteItem/GetRouteItemHandler';
import PrinterReaderAdapter from 'core/infrastructure/adapter/PrinterReaderAdapter';
import StorageEmployeeReader from 'core/infrastructure/persistence/employee/read/StorageEmployeeReader';
import HttpInventoryReader from 'core/infrastructure/persistence/inventory/HttpInventoryReader';
import HttpOrderReader from 'core/infrastructure/persistence/order/HttpOrderReader';
import HttpOrderReturnReader from 'core/infrastructure/persistence/orderReturn/HttpOrderReturnReader';
import HttpPalletReader from 'core/infrastructure/persistence/pallet/HttpPalletReader';
import HttpPrinterReader from 'core/infrastructure/persistence/printer/read/HttpPrinterReader';
import StoragePrinterReader from 'core/infrastructure/persistence/printer/read/StoragePrinterReader';
import HttpRouteReader from 'core/infrastructure/persistence/route/HttpRouteReader';
import HttpRouteItemReader from 'core/infrastructure/persistence/routeItem/HttpRouteItemReader';

const queryRegisterList = [
  {
    identifier: 'HttpRouteReader',
    dependencies: ['PickingHttpClient'],
    classInjection: HttpRouteReader,
  },
  {
    identifier: 'EmployeeReader',
    dependencies: ['LocalStorage'],
    classInjection: StorageEmployeeReader,
  },
  {
    identifier: 'HttpPalletReader',
    dependencies: ['ShippingHttpClient'],
    classInjection: HttpPalletReader,
  },
  {
    identifier: 'HttpInventoryReader',
    dependencies: ['WarehouseHttpClient'],
    classInjection: HttpInventoryReader,
  },
  {
    identifier: 'HttpOrderReturnReader',
    dependencies: ['ReturnHttpClient'],
    classInjection: HttpOrderReturnReader,
  },
  {
    identifier: 'StoragePrinterReader',
    dependencies: ['LocalStorage'],
    classInjection: StoragePrinterReader,
  },
  {
    identifier: 'HttpPrinterReader',
    dependencies: ['PrinterHttpClient'],
    classInjection: HttpPrinterReader,
  },
  {
    identifier: 'HttpOrderReader',
    dependencies: ['PickingHttpClient'],
    classInjection: HttpOrderReader,
  },
  {
    identifier: 'FindRoutesHandler',
    dependencies: ['HttpRouteReader'],
    classInjection: FindRoutesHandler,
  },
  {
    identifier: 'FindRouteWithPreparedTrolleyHandler',
    dependencies: ['HttpRouteReader'],
    classInjection: FindRouteWithPreparedTrolleyHandler,
  },
  {
    identifier: 'FindRoutesCountHandler',
    dependencies: ['HttpRouteReader'],
    classInjection: FindRoutesCountHandler,
  },
  {
    identifier: 'GetPreparerRouteHandler',
    dependencies: ['HttpRouteReader'],
    classInjection: GetPreparerRouteHandler,
  },
  {
    identifier: 'CheckCurrentEmployeeIsLoggedInHandler',
    dependencies: ['EmployeeReader'],
    classInjection: CheckCurrentEmployeeIsLoggedInHandler,
  },
  {
    identifier: 'GetCurrentEmployeeHandler',
    dependencies: ['EmployeeReader'],
    classInjection: GetCurrentEmployeeHandler,
  },
  {
    identifier: 'FindPalletHandler',
    dependencies: ['HttpPalletReader'],
    classInjection: FindPalletHandler,
  },
  {
    identifier: 'FindInventoryHandler',
    dependencies: ['HttpInventoryReader'],
    classInjection: FindInventoryHandler,
  },
  {
    identifier: 'FindOrderReturnHandler',
    dependencies: ['HttpOrderReturnReader'],
    classInjection: FindOrderReturnHandler,
  },
  {
    identifier: 'FindOrderHandler',
    dependencies: ['HttpOrderReader'],
    classInjection: FindOrderHandler,
  },
  {
    identifier: 'FindPrintersHandler',
    dependencies: ['PrinterReaderAdapter'],
    classInjection: FindPrintersHandler,
  },
  {
    identifier: 'GetOrdersWithPublicOrderIdHandler',
    dependencies: ['HttpOrderReader'],
    classInjection: GetOrdersWithPublicOrderIdHandler,
  },
  {
    identifier: 'GetUnsoldOrdersHandler',
    dependencies: ['HttpOrderReader'],
    classInjection: GetUnsoldOrdersHandler,
  },
  {
    identifier: 'PrinterReaderAdapter',
    dependencies: ['HttpPrinterReader', 'StoragePrinterReader'],
    classInjection: PrinterReaderAdapter,
  },
  {
    identifier: 'FindRouteByIdHandler',
    dependencies: ['HttpRouteReader'],
    classInjection: FindRouteByIdHandler,
  },
  {
    identifier: 'HttpRouteItemReader',
    dependencies: ['PickingHttpClient', 'PickingHttpClient'],
    classInjection: HttpRouteItemReader,
  },
  {
    identifier: 'GetRouteItemHandler',
    dependencies: ['HttpRouteItemReader'],
    classInjection: GetRouteItemHandler,
  },
  {
    identifier: 'ListNotificationsHandler',
    dependencies: ['NotificationRepository'],
    classInjection: ListNotificationsHandler,
  },
  {
    identifier: 'FindFinishedPallets',
    dependencies: ['HttpPalletReader'],
    classInjection: FindFinishedPallets,
  },
  {
    identifier: 'FindFinishedPalletsHandler',
    dependencies: ['HttpPalletReader'],
    classInjection: FindFinishedPalletsHandler,
  },
  {
    identifier: 'FindDraftPalletsHandler',
    dependencies: ['HttpPalletReader'],
    classInjection: FindDraftPalletsHandler,
  },
  {
    identifier: 'FindCouriersHandler',
    dependencies: ['CourierRepository'],
    classInjection: FindCouriersHandler,
  },
];

export { queryRegisterList };
