import { Command } from '@lookiero/messaging.js';

class RemoveInventory extends Command {
  readonly location: string;

  public constructor(location: string) {
    super();
    this.location = location;
  }

  /* istanbul ignore next */
  protected messageName(): string {
    return 'RemoveInventory';
  }
}

export default RemoveInventory;
