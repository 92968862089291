class Location {
  readonly value: string;

  private constructor(value: string) {
    this.value = value;
  }

  public static createLocation(location: string): Location {
    return new Location(location);
  }
}

export default Location;
