import HttpClient from 'shared/domain/delivery/HttpClient';
import RouteItemReader, { RouteItemCriteria } from '../../../domain/model/routeItem/read/RouteItemReader';
import RouteItemView from '../../../domain/model/routeItem/read/RouteItemView';
import {
  HttpOrderItemView,
  HttpQuantityView,
  orderItemFromHttpDto,
  OrderItemView,
  RouteItemStock,
  stockFromDto,
} from './HttpRouteItemView';

class HttpRouteItemReader implements RouteItemReader {
  private readonly pickingHttpClient: HttpClient;
  private readonly warehouseHttpClient: HttpClient;

  constructor(pickingHttpClient: HttpClient, warehouseHttpClient: HttpClient) {
    this.pickingHttpClient = pickingHttpClient;
    this.warehouseHttpClient = warehouseHttpClient;
  }

  async getRouteItem(criteria: RouteItemCriteria): Promise<RouteItemView> {
    const { orderId, orderItemId, location } = criteria;

    const orderItem = await this.getOrderItem(orderId, orderItemId);

    const stock = await this.getStock(orderItemId, location);

    return Promise.resolve({
      ...orderItem,
      stock,
    } as RouteItemView);
  }

  private async getOrderItem(orderId: string, orderItemId: string): Promise<OrderItemView> {
    return this.pickingHttpClient
      .get<HttpOrderItemView>(`/workorders/v1/${orderId}/references/${orderItemId}`)
      .then((orderItem) => orderItemFromHttpDto(orderItem));
  }

  private async getStock(orderItemId: string, location: string): Promise<RouteItemStock> {
    return this.warehouseHttpClient
      .get<HttpQuantityView>(`/v2/references/${orderItemId}/locations/${location}`)
      .then((quantity) => stockFromDto(quantity));
  }
}

export default HttpRouteItemReader;
