interface HttpClient {
  get<T>(endpoint: string, queryParams?: Record<string, unknown>, responseType?: ResponseType): Promise<T>;
  post<T>(endpoint: string, body: Record<string, unknown>, responseType?: ResponseType): Promise<T>;
  put<T>(endpoint: string, body: Record<string, unknown>, responseType?: ResponseType): Promise<T>;
  patch<T>(endpoint: string, body?: Record<string, unknown>, responseType?: ResponseType): Promise<T>;
  delete<T>(endpoint: string, responseType?: ResponseType): Promise<T>;
}

export type ResponseType = 'text' | 'json';

export interface HttpClientResponseError {
  readonly message: string;
  readonly method: string;
  readonly url: string;
  readonly status?: number;
  readonly body?: Record<string, unknown>;
}

export enum HttpErrorCodes {
  NOT_FOUND = 404,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  CONFLICT = 409,
  SERVER_ERROR = 500,
}

export default HttpClient;
