import DomainError from 'shared/domain/error/DomainError';
import ErrorCode from 'shared/domain/error/ErrorCode';
import { I18N_MESSAGES } from 'shared/i18n/picking';

class RouteNotFoundToAssignTrolleyError extends DomainError {
  public constructor() {
    super(
      ErrorCode.ROUTE_NOT_FOUND_TO_ASSIGN_TROLLEY,
      I18N_MESSAGES.ROUTE_NOT_FOUND_TO_ASSIGN_TROLLEY_ERROR,
      RouteNotFoundToAssignTrolleyError.name,
    );
  }
}

export default RouteNotFoundToAssignTrolleyError;
