import React from 'react';
import { NotificationProjection } from 'core/domain/model/notification/NotificationProjection';

import Notification from '../../molecules/notification/Notification';
import { Stack, StackElement } from '@lookiero/react-ui-kit';
import './notifications.css';

interface NotificationsProps {
  readonly notifications: NotificationProjection[];
  readonly onCancel?: (notification: NotificationProjection) => void;
}

const Notifications: React.FC<NotificationsProps> = ({ notifications, onCancel }: NotificationsProps) => (
  <Stack className="notifications">
    {notifications.map(
      (notification) =>
        (<Notification key={notification.id} notification={notification} onCancel={onCancel} />) as StackElement,
    )}
  </Stack>
);

export default Notifications;
