import { v4 as uuid } from 'uuid';
import { CommandBus, ProcessManager } from '@lookiero/messaging.js';
import CreateNotification from 'core/application/notification/createNotification/CreateNotification';
import NotificationLevel from 'core/domain/model/notification/NotificationLevel';
import { I18N_MESSAGES, PREFIX } from 'shared/i18n/returns';
import OrderReturnFinished from './OrderReturnFinished';

class OrderReturnFinishedListener implements ProcessManager<OrderReturnFinished> {
  commandBus!: CommandBus;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async process(event: OrderReturnFinished): Promise<void> {
    const notificationCommand = new CreateNotification(
      uuid(),
      NotificationLevel.SUCCESS,
      PREFIX + I18N_MESSAGES.NOTIFICATION_FINISH_ORDER_RETURN,
    );
    this.commandBus.dispatch(notificationCommand);
  }
}

export default OrderReturnFinishedListener;
