import { EventBus } from '@lookiero/messaging.js';
import CoreError from 'shared/domain/error/model/CoreError';
import ErrorRepository from 'shared/domain/error/model/ErrorRepository';

class InMemoryErrorRepository implements ErrorRepository {
  private eventBus!: EventBus;

  public async throw(error: CoreError): Promise<void> {
    try {
      this.eventBus.publish(error.recordedEvents());
    } catch (error) {
      console.log(error);
      throw new Error('Unknown error trying to launch command');
    }
  }
}

export default InMemoryErrorRepository;
