import { Query } from '@lookiero/messaging.js';

class GetPreparerRoute extends Query {
  public readonly preparerId: string;

  public constructor(preparerId: string) {
    super();
    this.preparerId = preparerId;
  }

  /* istanbul ignore next */
  protected messageName(): string {
    return 'GetPreparerRoute';
  }
}

export default GetPreparerRoute;
