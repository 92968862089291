import React from 'react';
import { Button, ButtonVariant, Text, TextVariant } from '@lookiero/react-ui-kit';
import './error-reporter.css';
import { ReactIntl } from '@lookiero/i18n.js';
import { I18N_MESSAGES, PREFIX } from 'shared/i18n/errorReporter';

interface ErrorReporterProps {
  readonly onReport: () => void;
}

const ErrorReporter: React.FC<ErrorReporterProps> = ({ onReport }: ErrorReporterProps) => {
  const messages = {
    title: ReactIntl.useI18nMessage({ prefix: PREFIX, id: I18N_MESSAGES.TITLE_CONTENT }),
    descriptionLine1: ReactIntl.useI18nMessage({ prefix: PREFIX, id: I18N_MESSAGES.DESCRIPTION_LINE_1 }),
    descriptionLine2: ReactIntl.useI18nMessage({ prefix: PREFIX, id: I18N_MESSAGES.DESCRIPTION_LINE_2 }),
    buttonReport: ReactIntl.useI18nMessage({ prefix: PREFIX, id: I18N_MESSAGES.BUTTON_REPORT }),
  };

  return (
    <div className="error-reporter">
      <Text className="error-reporter__title" variant={TextVariant.HEADING_2} tag="h2">
        {messages.title}
      </Text>
      <Text className="error-reporter__description" variant={TextVariant.BODY} tag="div">
        <p>{messages.descriptionLine1}</p>
        <p>{messages.descriptionLine2}</p>
      </Text>
      <Button variant={ButtonVariant.PRIMARY} onClick={onReport}>
        {messages.buttonReport}
      </Button>
    </div>
  );
};

export default ErrorReporter;
