import DomainError from 'shared/domain/error/DomainError';
import ErrorCode from 'shared/domain/error/ErrorCode';
import { I18N_MESSAGES } from 'shared/i18n/picking';

class TrolleyBarcodeBadFormatError extends DomainError {
  public constructor() {
    super(
      ErrorCode.TROLLEY_BARCODE_BAD_FORMAT,
      I18N_MESSAGES.TROLLEY_BARCODE_BAD_FORMAT_ERROR,
      TrolleyBarcodeBadFormatError.name,
    );
  }
}

export default TrolleyBarcodeBadFormatError;
