import DomainError from 'shared/domain/error/DomainError';
import ErrorCode from 'shared/domain/error/ErrorCode';
import { I18N_MESSAGES } from 'shared/i18n/returns';

class PrintOrderReturnReferenceFailedError extends DomainError {
  public constructor() {
    super(
      ErrorCode.PRINT_ORDER_RETURN_REFERENCE_FAILED_ERROR,
      I18N_MESSAGES.PRINT_ORDER_RETURN_REFERENCE_FAILED_ERROR,
      PrintOrderReturnReferenceFailedError.name,
    );
  }
}

export default PrintOrderReturnReferenceFailedError;
