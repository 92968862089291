import { Command } from '@lookiero/messaging.js';

class CreateEmployee extends Command {
  readonly userId: string;
  readonly authToken: string;
  readonly username: string;
  readonly roles: string[];

  constructor({
    userId,
    authToken,
    username,
    roles,
  }: {
    userId: string;
    authToken: string;
    username: string;
    roles: string[];
  }) {
    super();
    this.userId = userId;
    this.authToken = authToken;
    this.username = username;
    this.roles = roles;
  }

  /* istanbul ignore next */
  public messageName(): string {
    return 'CreateEmployee';
  }
}

export default CreateEmployee;
